<div *ngIf="notThirdParty; else thirdParty">
  <header align="center">
    <h1 class="home-title">Welcome to the GDCO App</h1>
    <p>
      This landing page is the default view for the GDCO App. Open the menu bar on top left and select an icon to jump
      right in.
    </p>
  </header>

  <main *gdcoPrivateCloud class="home-content" [ngClass]="{ mobile: isMobile$ | async }">
    <ng-container *gdcoNonPublicCloud>
      <!-- What's new column -->
      <div class="home-tile" align="center">
        <h2>What's New?</h2>
        <p>We want our application to continually improve and provide new capabilities for datacenter operations</p>
        <a routerLink="/message">Click here for the latest features available in the GDCO App!</a>
      </div>

      <!-- Training column -->
      <div class="home-tile" align="center">
        <h2>Training</h2>
        <p>
          GDCO Learning and Development curates training and learning resources specifically for GDCO employees to
          assist your career development.
        </p>
        <a href="https://aka.ms/gdcolearning" target="_blank" rel="noreferrer"
          >Click here for the latest GDCO Training!</a
        >
      </div>

      <!-- Contact us column -->
      <div class="home-tile" align="left">
        <h2 align="center">Contact Us</h2>
        <p>Have questions? Here are key GDCO App Resources:</p>
        <ul>
          <li>
            <a routerLink="/noaccess">Access/Permission issues</a>
          </li>
          <li>
            <a href="mailto:gdcoappquestions@microsoft.com">General Questions</a>
          </li>
          <li>
            <a routerLink="/requests/forms/10">Bug/Issue Reporting</a>
          </li>
          <li>
            <a routerLink="/requests/forms/11">Feature Requests</a>
          </li>
          <li>
            <a href="https://aka.ms/feature-request-powerbi-dcis" target="_blank" rel="noreferrer"
              >Dashboard View of Feature Requests</a
            >
          </li>
          <li>
            <a href="https://aka.ms/gdcolearning" target="_blank" rel="noreferrer">GDCO App Training*</a>
          </li>
        </ul>
        <div>* Relevant only for Microsoft technicians.</div>
      </div>
    </ng-container>
  </main>
</div>
<ng-template #thirdParty>
  <header align="center">
    <h1 class="home-title">Welcome to the GDCO App</h1>
    <p>
      This landing page is the default view for the GDCO App. Open the menu bar on top left and select an icon to jump
      right in.
    </p>
  </header>

  <main *gdcoPrivateCloud class="home-content" [ngClass]="{ mobile: isMobile$ | async }">
    <!-- Contact us column -->
    <div class="home-tile" align="center">
      <h2 align="center">Contact Us</h2>
      <p>Have questions? Here are key GDCO App Resources:</p>
      <ul>
        <li>
          <b>General Questions: </b>
          <a href="mailto:gdcoappquestions@microsoft.com">gdcoappquestions@microsoft.com</a>
        </li>
        <li>
          <b>Bug/Issue Reporting: </b>
          <a routerLink="/requests/forms/10">{{ origin }}/requests/forms/10</a>
        </li>
      </ul>
      <div>* Relevant only for Microsoft technicians.</div>
    </div>
  </main>
</ng-template>
