<gdco-label *ngIf="_formFieldLabel || label" [required]="required">
  <ng-container *ngIf="_formFieldLabel; else defaultLabelTemplate">
    <ng-container *ngTemplateOutlet="_formFieldLabel.template"></ng-container>
  </ng-container>
  <ng-template #defaultLabelTemplate>
    {{ label }}
  </ng-template>
</gdco-label>
<mat-form-field>
  <mat-select
    [aria-label]="label"
    gdcoMatSelectAccessibility
    [formControl]="formControl"
    [placeholder]="placeholder"
    [compareWith]="compareWith"
    [required]="required"
    (selectionChange)="onOptionSelected($event)"
  >
    <mat-option *ngFor="let option of options$ | async" [value]="option" [attr.title]="displayFn(option)">{{
      displayFn(option)
    }}</mat-option>
  </mat-select>
  <mat-spinner *ngIf="loading" matSuffix [diameter]="20"></mat-spinner>
  <mat-hint *ngIf="hint">{{ hint }}</mat-hint>
  <!-- <ng-container *ngFor="let errors of errorMessages | keyvalue">
    <mat-error *ngIf="formControl.hasError(errors.key)">{{ errors.value }}</mat-error>
  </ng-container> -->
</mat-form-field>
