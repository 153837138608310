<gdco-dialog-container
  [dialogTitle]="title"
  size="small"
  [dialogRef]="dialogRef"
  [color]="color"
  [allowMove]="true"
  [disableCloseAction]="notification?.gdcoDialogComponentOptions?.disableCloseAction"
>
  <div class="notification-dialog-container">
    <div class="notification-dialog-content">
      <h4>Message</h4>
      <p class="notification-text">{{ notification?.message }}</p>
      <h4 class="notification-details">Details</h4>
      <gdco-sanitize class="notification-text" [html]="details"></gdco-sanitize>
      <div *ngIf="notification.link" class="notification-link">
        <a href="{{ notification.link }}" target="_blank" rel="noreferrer" gdcoTrack="NotificationLinkClicked">
          {{ notification.linkText || notification.link }}
        </a>
      </div>
    </div>
  </div>
</gdco-dialog-container>
