<mat-dialog-content>
  <div
    class="gdco-dialog-container"
    fxLayout="column"
    [ngClass]="{
      maximized: maximized,
      lg: !maximized && size === 'large',
      md: !maximized && size === 'medium',
      sm: !maximized && size === 'small',
      xs: !maximized && size === 'extra-small'
    }"
    [@.disabled]="animationsDisabled$ | async"
  >
    <mat-toolbar
      [color]="color"
      [style.backgroundColor]="backgroundColor"
      [style.color]="textColor"
      (mousedown)="onMouseDown($event)"
      [ngClass]="{ 'gdco-movable': !maximized && allowMove }"
    >
      <h1>{{ dialogTitle }}</h1>
      <div fxFlex></div>
      <button
        *ngFor="let action of actions"
        mat-icon-button
        [attr.aria-label]="action.tooltip"
        (click)="actionClicked(action)"
      >
        <mat-icon>{{ action.icon }}</mat-icon>
      </button>
      <button aria-label="Maximize" *ngIf="allowMaximize" mat-icon-button (click)="toggleMaximize()">
        <mat-icon>{{ maximized ? 'fullscreen_exit' : 'fullscreen' }}</mat-icon>
      </button>
      <button aria-label="Close" *ngIf="dialogRef && !disableCloseAction" mat-icon-button (click)="close()">
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar>

    <div fxFlex fxLayout="column" class="gdco-dialog-content">
      <ng-content></ng-content>
    </div>
  </div>
</mat-dialog-content> 
