<gdco-sidenav-panel [label]="label" (close)="close.emit()">
  <gdco-sidenav-content>
    <div fxLayout="column" fxLayoutGap="16px">
      <div *ngIf="feedbackTitle" class="feedback-title">
        {{ feedbackTitle }}
      </div>
      <mat-chip-grid aria-orientation="vertical" class="mat-chip-list-stacked">
        <mat-chip-row *ngFor="let option of selectableFeedbackOptions" (click)="selectFeedbackType(option.item.type)">
          <mat-icon>{{ option?.item?.icon }}</mat-icon
          >&nbsp;{{ option?.item?.name }}
        </mat-chip-row>
      </mat-chip-grid>
      <div *ngIf="includeComment">
        <mat-form-field class="text-width">
          <textarea matInput rows="16" [formControl]="feedbackTextControl"> </textarea>
        </mat-form-field>
      </div>
      <div *ngIf="includeScreenshot">
        <mat-checkbox [formControl]="screenshotControl"> Include screenshot </mat-checkbox>
      </div>
    </div>
  </gdco-sidenav-content>

  <gdco-sidenav-footer>
    <button mat-flat-button color="primary" (click)="onSubmitFeedback()">Submit feedback</button>
  </gdco-sidenav-footer>
</gdco-sidenav-panel>
