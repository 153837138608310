import { Component, Input } from '@angular/core';
import { AssistantMessage } from '../models';

@Component({
  selector: 'assistant-message-header',
  templateUrl: './assistant-message-header.component.html'
})
export class AssistantMessageHeaderComponent {
  @Input() message!: AssistantMessage;

  copyToClipboard = () => {
    if (this.message instanceof AssistantMessage) {
      navigator.clipboard.writeText(this.message.concatSegments())
        .then(() => {
          // toast.success("Copied to clipboard");
        }).catch((err) => {
          // toast.error("Failed to copy text to clipboard");
        });
    }
  };
}
