<div class="danaui-chat-message-header">
  <div class="left-side">
    <span>{{message.formattedDateTime}}</span>
  </div>

  <div class="right-side">
    <button mat-icon-button matTooltip="Copy text" aria-label="Copy text" (click)="copyToClipboard()">
      <mat-icon>content_copy</mat-icon>
    </button>
  </div>
</div>