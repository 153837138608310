<div class="toc-header">On this page</div>
<div class="toc-contents">
  <div
    *ngFor="let line of tableOfContents"
    [style.fontSize]="16 - 2 * (line.indent - 1) + 'px'"
    [style.marginLeft]="8 * (line.indent - 1) + 'px'"
    class="toc-link"
  >
    <a [routerLink]="currentUrl" [fragment]="line.url">
      {{ line.text }}
    </a>
  </div>
</div>
