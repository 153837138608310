<gdco-dialog-container
  [dialogTitle]="title"
  size="large"
  [dialogRef]="dialogRef"
  [actions]="actions"
  [allowMaximize]="true"
  [allowMove]="false"
  (actionClick)="actionClicked($event)"
>
  <mat-tab-group dynamicHeight (selectedIndexChange)="onSelectedIndexChanged($event)">
    <mat-tab class="tabBody" label="Results">
      <gdco-load-application name="tasks-list" (load)="onComponentLoad($event)"></gdco-load-application>
    </mat-tab>
    <mat-tab class="tabBody" label="Query">
      <div *ngIf="showQuery" style="padding: 20px; overflow-y: auto" fxFill fxLayout="column">
        <gdco-query-builder
          displayTitle="Query"
          [disabled]="true"
          [query]="query"
          [options]="options"
          fxFlex
        ></gdco-query-builder>
        <div *ngIf="clientFilterDisplay">
          <h4>Client Filter</h4>
          <div fxLayout="row">
            <mat-form-field fxFlex>
              <textarea matInput disabled="true" rows="6">{{ clientFilterDisplay }}</textarea>
            </mat-form-field>
          </div>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</gdco-dialog-container>
