/**
 * Supported message formats; for plain text, use 'markdown'
 */
export type MessageFormat = 'markdown' | 'mermaid' | 'query';

/**
 * Represents a segment of a chat message
 */
export class MessageSegment {
    text: string;
    type: MessageFormat;
    kustoLink?: string;
    rowCount?: number;
    colCount?: number;
    rowExceedMax?: boolean;
    colExceedMax?: boolean;
    result?: string;
    error?: string;

    /**
     * Construct a new message segment
     * @param text The text of the segment
     * @param type The format of the segment
     * @param kustoLink
     * @param rowCount 
     * @param colCount 
     * @param rowExceedMax 
     * @param colExceedMax 
     * @param result 
     * @param error 
     */
    constructor(text: string, type: MessageFormat, kustoLink?: string,
        rowCount?: number, colCount?: number, rowExceedMax?: boolean, colExceedMax?: boolean, result?: string, error?: string) {
        this.text = text;
        this.type = type;
        this.kustoLink = kustoLink;
        this.rowCount = rowCount;
        this.colCount = colCount;
        this.rowExceedMax = rowExceedMax;
        this.colExceedMax = colExceedMax;
        this.result = result;
        this.error = error;

        this.formatText();
    }

    /**
     * Format the text based on the segment type
     */
    private formatText() {
        switch (this.type) {
            case 'mermaid':
                this.formatMermaid();
                break;
            default:
                this.formatMarkdown();
                break;
        }
    }

    /**
     * Format the text of a markdown segment
     */
    private formatMarkdown() {
        this.text = this.text.trim()
    }

    /**
     * Format the text of a mermaid diagram
     */
    private formatMermaid() {
        // Split the diagram string by newlines
        let lines = this.text.trim().split('\n');

        // Remove the code block markers if present
        if (lines.length > 2) {
            if (lines[0].trim().startsWith('```') || lines[lines.length - 1].trim().startsWith('```')) {
                lines = lines.slice(1, -1);
            }
        }

        // Only format if there's valid mermaid content
        if (lines.length > 0 && lines[0].trim().length > 0) {
            // Format lines, keeping the first line unindented
            const formattedDiagram = lines.map((line, index) => {
                const trimmedLine = line.trim(); // Trim to avoid trailing spaces
                // If it's the first line, return it unindented; otherwise, indent it
                return index === 0 ? trimmedLine : `    ${trimmedLine}`;
            }).join('\n');

            this.text = formattedDiagram; // Set the formatted text
        }
    }
}