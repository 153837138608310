import { HandlerType } from "./handler-type";

/**
 * WebHandlerType
 */
const webHandlerType: HandlerType = {
  value: 'web_endpoint',
  label: 'Web Endpoint',
  description: 'A web service / HTTPS Endpoint',
  icon: 'bi-plug',
  defaultOptions: () => ({
    web_url: '',
    web_method: 'GET',
    authentication_strategy: 'none'
  })
};

/**
 * NativeHandlerType
 */
const nativeHandlerType: HandlerType = {
  value: 'native',
  label: 'Native',
  description: 'A native function implemented within the DANA server',
  icon: 'bi-hammer',
  defaultOptions: () => ({})
};

/**
 * UIMessageHandlerType
 */
const uiMessageHandlerType: HandlerType = {
  value: 'ui_message',
  label: 'UI Message',
  description: 'An event for the user interface, e.g. focus an item',
  icon: 'bi-window',
  defaultOptions: () => ({})
};

/**
 * List of handler types
 */
export const handlerTypes: HandlerType[] = [nativeHandlerType, webHandlerType, uiMessageHandlerType];