<div class="page-container">
  <div class="page-container-title">
    <ng-container *ngIf="_labelTemplate; else labelText">
      <ng-container *ngTemplateOutlet="_labelTemplate.template"></ng-container>
    </ng-container>
    <ng-template #labelText>
      <h1 role="heading">{{ label }}</h1>
    </ng-template>

    <gdco-page-container-subscript *ngIf="subscript; else subscriptTemplate"></gdco-page-container-subscript>
    <ng-template #subscriptTemplate>
      <ng-content select="gdco-page-container-subscript"></ng-content>
    </ng-template>
  </div>

  <div class="page-container-contents" role="main">
    <ng-content></ng-content>
  </div>
</div>
