import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GdcoLocalStorage } from '@gdco/core';
import { AssistantUserSettings } from '@gdco/reference-systems/gdco-service';
import { AssistantStorageService } from '../../assistant/assistant-storage.service';
import { AssistantChatService } from '../services';
import { AssistantChatAssistantChooser } from '.';
import { AssistantConstants, DEFAULT_USER_SETTINGS } from '../../assistant/assistant.constants';
import { Assistant, AssistantChatMessage, AssistantMessage, AssistantWaitingMessage, Session } from '../models';

@Component({
  selector: 'assistant-chat',
  templateUrl: './assistant-chat.component.html',
  styleUrls: ['./assistant-chat.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AssistantChatComponent implements OnInit, OnDestroy {
  @Input() sessionId!: string;
  @Input() shortName!: string;

  private destroy$ = new Subject<void>();
  messages: AssistantChatMessage[] = [];
  assistant: Assistant;
  session: Session;
  error: string | null = null;
  private _assistantUserSettings: AssistantUserSettings | null;

  get userSettings(): AssistantUserSettings {
    return this._assistantUserSettings ?? DEFAULT_USER_SETTINGS;
  }

  constructor(
    private dialog: MatDialog,
    private localStorage: GdcoLocalStorage,
    private changeDetector: ChangeDetectorRef,
    private assistantChatService: AssistantChatService,
    private assistantStorageService: AssistantStorageService
  ) {
    this.loadUserSettings();
  }

  /**
   * Initialize the chat component by starting the chat
   */
  async ngOnInit(): Promise<void> {
    this.subcribeToMessages();
    await this.startChat();
  }

  /**
   * Unsubscribe from all subscriptions when the component is destroyed
   */
  ngOnDestroy(): void {
    // Emit a value to the destroy$ subject to complete all subscriptions
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * Subscribe to messages from the assistant chat service
   */
  subcribeToMessages = (): void => {
    // Subscribe to messages$ and use takeUntil to automatically unsubscribe
    this.assistantChatService.messages$
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (messages: AssistantChatMessage[]) => {
          this.removeLoading();
          this.addMessages(messages);
        },
        (error) => {
          console.error('Error receiving messages:', error);
        }
      );
  };

  /**
   * Initialize the chat with the assistant's greeting message
   */
  greetings = (): void => {
    const greeting = new AssistantMessage({ text: this.assistant?.greeting, messageRole: 'assistant' });
    this.addMessage(greeting);

    if (this.assistant?.displayDisclaimer && this.assistant?.disclaimer) {
      const disclaimer = new AssistantMessage({ text: this.assistant.disclaimer, messageRole: 'assistant' });
      this.addMessage(disclaimer);
    }
  };

  /**
   * Allow the user to retry connecting
   */
  retry = async (): Promise<void> => {
    this.error = null;
    this.assistant = null;
    this.shortName = null;
    this.sessionId = null;
    this.updateUserSettings();
    await this.startChat();
  };

  /**
   * Start the chat by getting the assistant and session
   */
  startChat = async (): Promise<void> => {
    this.clearMessages();
    this.addLoading();

    // Get the assistant and session; if no assistant is provided, then show the assistant chooser dialog
    if (this.shortName) {
      let sessionMessages: AssistantChatMessage[] = [];
      const assistantExists = await this.tryLoadAssistant(this.shortName);

      if (assistantExists && !!this.sessionId) {
        sessionMessages = await this.tryLoadSession(this.sessionId);
      }

      this.removeLoading();
      this.greetings();
      this.addMessages(sessionMessages);
    } else {
      this.handleChooseAssistant();
    }
  };

  /**
   * Try to load the assistant from the assistant service
   * If there is an error, set the error message and return false
   * @param shortname Assistant shortname
   * @returns 
   */
  private tryLoadAssistant = async (shortname: string): Promise<boolean> => {
    let result = true;
    try {
      this.assistant = await this.assistantChatService.getAssistant(shortname, true);
    } catch {
      console.log('Error loading assistant:', shortname);
      this.error = 'An error occurred while starting the chat. Please try again.';
      result = false;
    }

    return result;
  };

  /**
   * Try to load the session from the assistant service
   * If there is an error, clear the messages and session and return an empty array
   * @param sessionId 
   * @returns 
   */
  private tryLoadSession = async (sessionId: string): Promise<AssistantChatMessage[]> => {
    try {
      this.session = await this.assistantChatService.getSession(sessionId, true);
      const sessionMessages = this.assistantChatService.getMessagesFromSession(this.session);
      return sessionMessages;
    } catch {
      console.log('Error loading session:', sessionId);
      this.clearMessages();
      this.clearSession();
      return [];
    }
  };

  /**
   * Load the user settings from local storage
   */
  loadUserSettings = (): void => {
    this._assistantUserSettings = this.localStorage.get(AssistantConstants.gdcoAssistantUserSettings);
    if (this._assistantUserSettings) {
      this.shortName = this._assistantUserSettings.shortname;
      this.sessionId = this._assistantUserSettings.sessionId;
    }
  };

  /**
   * Update the user settings in local storage
   */
  updateUserSettings = (): void => {
    this.userSettings.shortname = this.shortName;
    this.userSettings.sessionId = this.sessionId;
    this.localStorage.set(AssistantConstants.gdcoAssistantUserSettings, this.userSettings);
  };

  /**
   * Clear the current session
   */
  clearSession = (): void => {
    this.assistantChatService.removeSession(this.sessionId);
    this.sessionId = null;
    this.assistantChatService.session = null;
    this.updateUserSettings();
  };

  /**
   * Initiate a new chat session with the already loaded assistant by removing the session and messages from storage
   */
  handleNewChat = (): void => {
    this.clearMessages();
    this.clearSession();
    this.greetings();
  };

  /**
   * Choose a new assistant
   */
  handleChooseAssistant = (): void => {
    const dialogRef = this.showAssistantChooserDialog();

    // Handle the result of the dialog
    dialogRef
      .afterClosed()
      .subscribe((result: { assistant?: Assistant; error?: string }) => {
        if (result?.error) {
          // Handle the error if provided
          this.error = result.error;
          this.changeDetector.detectChanges();
        } else if (result?.assistant) {
          // If an assistant was selected, proceed with starting the chat
          this.shortName = result.assistant.shortname;
          this.userSettings.shortname = result.assistant.shortname;
          this.clearSession();

          // Call startChat and handle its promise directly
          this.startChat()
            .catch(err => {
              console.error('Error starting chat:', err);
            });
        }
      });
  };

  /**
   * Add a message to the messages state and trigger change detection
   * @param newMessage The message to add to the messages state
   */
  addMessage = (newMessage: AssistantChatMessage): void => {
    this.messages = [...this.messages, newMessage];
    this.updateMessageStorage();
    this.changeDetector.detectChanges();
  };

  /**
   * Add multiple messages to the messages state and trigger change detection
   * @param newMessages The messages to add to the messages state
   */
  addMessages = (newMessages: AssistantChatMessage[]): void => {
    if (newMessages?.length > 0) {
      this.messages = [...this.messages, ...newMessages];
      this.updateMessageStorage();
      this.changeDetector.detectChanges();
    }
  };

  /**
   * Remove all messages from the messages state
   */
  clearMessages = (): void => {
    this.messages = [];
    this.changeDetector.detectChanges();
  };

  updateMessageStorage = (): void => {
    if (this.sessionId) {
      const storageKey = `dana_session_${this.sessionId}_messages`;
      this.assistantStorageService.save({ key: storageKey, data: this.messages, expirationMins: 10 });
    }
  };

  retrieveMessageStorage = (): void => {
    if (this.sessionId) {
      const storageKey = `dana_session_${this.sessionId}_messages`;
      this.messages = this.assistantStorageService.load(storageKey);
    }
  };

  /**
   * Add a loading message to the the messages state
   */
  addLoading = (): void => {
    const waitingMessage: AssistantWaitingMessage = { type: 'waiting' };
    this.addMessage(waitingMessage);
  };

  /**
   * Remove the loading message from the messages state
   */
  removeLoading = (): void => {
    this.messages = this.messages.filter(msg => {
      if ('type' in msg && msg.type === 'waiting') { return false; }
      return true;
    });
  };

  /**
   * Send a message to an assistant; this will add the message to the messages and add a loading message
   * @param message 
   */
  async sendMessage(message: string): Promise<void> {
    const userMessage = typeof message === 'string'
      ? new AssistantMessage({ text: message, messageRole: 'user' })
      : message;

    this.addMessage(userMessage);
    this.addLoading();

    // Call the service method to send the message to the assistant
    try {
      const sessionId = await this.assistantChatService.sendMessage(message, this.shortName, this.sessionId);
      if (sessionId) {
        this.sessionId = sessionId;
        this.updateUserSettings();
      }
    } catch (error) {
      this.removeLoading();

      const errorText = `An error occurred while sending the message. Please try again.\n\n${error}`;
      const errorMessage = new AssistantMessage({ text: errorText, messageRole: 'assistant' });
      this.addMessage(errorMessage);
      console.error('Error sending message:', error);
    }
  }

  sessionDate(sessionId: string): string {
    const datePart = sessionId.split('-')[1];
    const month = parseInt(datePart.substring(4, 6), 10);
    const day = parseInt(datePart.substring(6, 8), 10);
    return `${month}/${day}`;
  }

  /**
   * Use the assistant chooser dialog to select a new assistant
   * @returns MatDialogRef<AssistantChatAssistantChooser>
   */
  private showAssistantChooserDialog = (): MatDialogRef<AssistantChatAssistantChooser> => {
    const dialogRef = this.dialog.open(AssistantChatAssistantChooser, {
      width: 'auto', minWidth: '400px', maxWidth: '800px', autoFocus: false
    });

    return dialogRef;
  };
}