/**
 * Represents the feedback provided by the user for an assistant message.
 */
export class AssistantMessageFeedback {
  source_id: string;
  source_name: string;
  source_type: string;
  session_id: string;
  ticket_id: string;

  source_data: string;
  source_data_timestamp: string;

  user_id: string;
  user_email: string;

  harmful: boolean;
  untrue: boolean;
  unhelpful: boolean;
  feedback_details: string;
  contact_preference: 'yes' | 'no';
  is_positive: boolean;

  public constructor(init?: Partial<AssistantMessageFeedback>) {
    this.source_id = init?.source_id ?? '';
    this.source_name = init?.source_name ?? '';
    this.source_type = init?.source_type ?? 'assistant';
    this.session_id = init?.session_id ?? '';
    this.source_data = init?.source_data ?? '';
    this.source_data_timestamp = init?.source_data_timestamp ?? '';
    this.feedback_details = init?.feedback_details ?? '';
    this.user_id = init?.user_id ?? '';
    this.user_email = init?.user_email ?? '';

    if (init?.harmful === undefined) {
      this.harmful = false;
    } else {
      this.harmful = init.harmful;
    }

    if (init?.untrue === undefined) {
      this.untrue = false;
    } else {
      this.untrue = init.untrue;
    }

    if (init?.unhelpful === undefined) {
      this.unhelpful = false;
    } else {
      this.unhelpful = init.unhelpful;
    }

    if (init?.contact_preference === undefined) {
      this.contact_preference = 'yes';
    } else {
      this.contact_preference = init.contact_preference;
    }

    if (init?.is_positive === undefined) {
      this.is_positive = true;
    } else {
      this.is_positive = init.is_positive;
    }
  }
}