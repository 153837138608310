<div class="card-arrow" gdcoElevation="4"></div>
<mat-card class="cards-container" gdcoElevation="4" id="gdco-notification-card-popup">
  <div class="cards-header-container">
    <div class="cards-header">
      <ng-container *ngIf="count > 0; else noNotificationsHeader">
        <ng-container *ngIf="count === 1; else moreThanOneMessageTemplate">
          <div>{{ count }} new message</div>
        </ng-container>
        <ng-template #moreThanOneMessageTemplate>
          <div>{{ count }} new messages</div>
        </ng-template>
      </ng-container>
      <ng-template #noNotificationsHeader>
        <div>No new messages</div>
      </ng-template>
      <div class="stretch"></div>
      <a
        class="notification-center"
        routerLink="/notifications"
        gdcoTrack="NotificationCenterClicked"
        [gdcoTrackData]="{ source: 'NotificationCards', count: count }"
        role="button"
        [attr.tabindex]="tabIndex"
        [attr.aria-disabled]="!interactive"
        (click)="_onNotificationCenterClicked()"
      >
        Notification center
      </a>
    </div>

    <div class="cards-subheader">
      <a
        *ngIf="clearable"
        class="clear-all"
        gdcoTrack="ClearAllNotificationsClicked"
        [gdcoTrackData]="{ source: 'NotificationCards', count: count }"
        role="button"
        [attr.tabindex]="tabIndex"
        [attr.aria-disabled]="!interactive"
        href="javascript:void(0)"
        (click)="_onClearAllClicked()"
        >Clear all</a
      >
      <div class="stretch"></div>
      <a
        *gdcoAuth="['Notifications']; action: 'Create'"
        class="new-notification"
        routerLink="/notifications/new"
        gdcoTrack="WriteNewNotificationClicked"
        [gdcoTrackData]="{ source: 'NotificationCards' }"
        role="button"
        [attr.tabindex]="tabIndex"
        [attr.aria-disabled]="!interactive"
        (click)="_onWriteNewNotificationClicked()"
      >
        <strong>Write new notification</strong>
      </a>
    </div>

    <mat-divider *ngIf="count > 0"></mat-divider>
  </div>

  <ng-content></ng-content>

  <div class="cards-footer-container">
    <div class="cards-footer">
      <div class="stretch"></div>
      <a
        role="button"
        href="javascript:void(0)"
        (click)="_onCloseClicked()"
        [attr.tabindex]="tabIndex"
        [attr.aria-disabled]="!interactive"
      >
        <strong>Close</strong>
      </a>
    </div>
  </div>
</mat-card>
<div class="card-arrow"></div>
