import { formatMessage } from '@coie/application-shell';
import { Extension, ExtensionAction } from '@coie/application-shell';

export class NotificationExtension extends Extension {
    name: string;
    svgIconPath: string;
    action: ExtensionAction;
    navigateUrl?: string;
    dropdownItems: { label: string; link: string }[];
    tooltip: string;

    constructor() {
        super();
        this.name = 'notification';
        this.svgIconPath = 'assets/icons/shellv2/alert_20_regular.svg';
        this.action = 'DropdownMenu';
        this.dropdownItems = [
            { label: formatMessage('notificationCenter'), link: '/notifications' },
            { label: formatMessage('writeNewNotification'), link: '/notifications/new' },
        ];
        this.tooltip = formatMessage('notifications');
    }

    async render(): Promise<string> {
        return `
            <div class="user-extension">
                <div class="icon" id="${this.name}-icon">${await this.getIcon()}</div>
                <div class="dropdown-menu" id="${this.name}-dropdown">
                    ${this.generateDropdownHtml()}
                </div>
            </div>
        `;
    }
}
