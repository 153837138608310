<ng-container *ngIf="notificationRef?.current | async as notification">
  <div class="notification-header">
    <mat-icon class="notification-icon">
      {{ notification.type | notificationIcon }}
    </mat-icon>
    <strong class="notification-subject" [attr.title]="notification.subject">{{ notification.subject }}</strong>
    <div class="stretch"></div>
    <button
      *ngIf="!notification.requiresAcknowledgement || notification.acknowledged"
      mat-icon-button
      class="close-button"
      [attr.aria-label]="'Dismiss the notification ' + notification.subject"
      gdcoTrack="DismissNotificationClicked"
      [gdcoTrackData]="trackData"
      role="button"
      [attr.tabindex]="tabIndex"
      [attr.aria-disabled]="!interactive"
      (click)="onDismissNotificationClicked($event)"
    >
      <mat-icon class="close">close</mat-icon>
    </button>
  </div>
  <div class="notification-subheader">
    <div class="notification-sender">{{ notificationRef.sender | async }}</div>
    <div class="stretch"></div>
    <div class="notification-date">{{ notification.createdDate | dateTimeFormat }}</div>
  </div>

  <div class="notification-message">
    <gdco-sanitize [html]="message"></gdco-sanitize>
    <a
      *ngIf="message?.length > maxMessageLength && !isError"
      class="see-more"
      routerLink="/notifications"
      gdcoTrack="SeeMoreNotificationsClicked"
      [gdcoTrackData]="trackData"
      [queryParams]="{ id: notification.id }"
      role="button"
      [attr.tabindex]="tabIndex"
      [attr.aria-disabled]="!interactive"
      (click)="onSeeMoreClicked()"
    >
      <strong>See more</strong>
    </a>
    <div *ngIf="isError">
      <button
        mat-button
        color="primary"
        gdcoTrack="FullNotificationDetailsClicked"
        [gdcoTrackData]="{ source: 'NotificationCards' }"
        role="button"
        [attr.tabindex]="tabIndex"
        [attr.aria-disabled]="!interactive"
        (click)="onFullDetailsClicked()"
      >
        Full details
      </button>
    </div>
  </div>

  <div *ngIf="notification.link" class="notification-link">
    <a
      [attr.href]="validLink"
      target="_blank"
      rel="noreferrer"
      [ngClass]="{ 'allow-click': !interactive }"
      gdcoTrack="NotificationLinkClicked"
      [gdcoTrackData]="trackData"
      role="button"
      [attr.aria-disabled]="!interactive"
      [attr.tabindex]="tabIndex"
      [attr.title]="notification.linkText || notification.link"
    >
      {{ notification.linkText || notification.link }}
    </a>
  </div>

  <div *ngIf="notification.requiresAcknowledgement" class="notification-link">
    <div *ngIf="notification.acknowledged; else notAcknowledgedTemplate" class="notification-acknowledged">
      <ng-container *ngIf="notification.acknowledgedDate">
        Acknowledged on {{ notification.acknowledgedDate | dateFormat }} at
        {{ notification.acknowledgedDate | amDateFormat: 'h:mm a' }}
      </ng-container>
    </div>
    <ng-template #notAcknowledgedTemplate>
      <a
        class="acknowledge"
        gdcoTrack="AcknowledgeNotificationClicked"
        [gdcoTrackData]="trackData"
        role="button"
        [attr.aria-disabled]="!interactive"
        [attr.tabindex]="tabIndex"
        href="javascript:void(0)"
        (click)="onAcknowledgeClicked()"
      >
        <strong>Acknowledge this message</strong>
      </a>
    </ng-template>
  </div>
</ng-container>
