import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { GDCO_ENVIRONMENT } from '@gdco/core';
import { GdcoEnvironmentConfig } from '@gdco/core-reference-systems/common';
import { Assistant, AssistantChatRequest, AssistantChatResponse, AssistantMessageFeedback, Session } from '../models';
import { NO_DG_ACCESS_ERROR } from '../constants';

@Injectable({ providedIn: 'root' })
export class AssistantChatAccessor {
  private readonly _urlBase: string;

  constructor(private _http: HttpClient,
    @Inject(GDCO_ENVIRONMENT) private _environmentConfig: GdcoEnvironmentConfig) {
    this._urlBase = `${this._environmentConfig.serviceUrls.danaService}/api`;
  }

  /**
   * Send a message to the assistant
   * @param shortname 
   * @param sessionId 
   * @param message 
   * @returns 
   */
  sendMessage(shortname: string, sessionId: string, message: AssistantChatRequest): Observable<AssistantChatResponse> {
    const endpoint = `${this._urlBase}/assistants/${shortname}/sessions/${sessionId}/`;
    return this._http
      .put<AssistantChatResponse>(endpoint, message, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        observe: 'response'
      })
      .pipe(
        map(response => response?.body || null),
        catchError(error => {
          return throwError(error);
        })
      );
  }

  /**
   * Create a new session with the assistant
   * @param shortname 
   * @param messageText 
   * @returns 
   */
  createSession(shortname: string, messageText?: string): Observable<AssistantChatResponse> {
    const endpoint = `${this._urlBase}/assistants/${shortname}/sessions/`;
    const data: object = messageText ? { session_name: messageText } : {};

    return this._http
      .post<AssistantChatResponse>(endpoint, data, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        observe: 'response'
      })
      .pipe(
        map(response => response?.body || null),
        catchError(error => {
          return throwError(error);
        })
      );
  }

  /**
   * Retrieve session from the API
   * @param sessionId - The ID of the session to retrieve
   * @returns Observable<Session>
   */
  getSession(sessionId: string): Observable<Session> {
    const endpoint = `${this._urlBase}/sessions/${sessionId}/`;
    return this._http
      .get<Session>(endpoint)
      .pipe(
        catchError(error => {
          if (error instanceof Error && error.message === NO_DG_ACCESS_ERROR) {
            return throwError(error);
          }
          return throwError(error);
        })
      );
  }

  /**
   * Retrieve chat response from the API
   * @param shortname - The assistant's short name
   * @param currentSessionId - The current session ID
   * @returns Observable<AssistantChatResponse>
   */
  getChatResponse(shortname: string, sessionId: string): Observable<AssistantChatResponse> {
    const endpoint = `${this._urlBase}/assistants/${shortname}/sessions/${sessionId}/`;
    return this._http
      .get<AssistantChatResponse>(endpoint)
      .pipe(
        catchError(error => {
          console.error('Error retrieving chat response:', error);
          return throwError(error); // Rethrow the error for further handling
        })
      );
  }

  /**
     * Retrieve an assistant from the API
     * @param shortname - The identifier for the assistant
     * @param routeSessionId - Optional session ID to retrieve previous messages
     * @returns Observable<Assistant>
     */
  getAssistant = (shortname: string): Observable<Assistant> => {
    const endpoint = `${this._urlBase}/assistants/${shortname}/`;
    return this._http
      .get<Assistant>(endpoint, { observe: 'response' })
      .pipe(
        map(response => {
          const data = response.body;
          if (!data) { throw new Error('Assistant not found'); }
          const assistant = new Assistant(data);
          return assistant;
        }),
        catchError(error => {
          // If error is a DG access error
          if (error instanceof Error && error.message === NO_DG_ACCESS_ERROR) {
            return throwError(error); // Re-throw the error
          }
          // Handle the error (e.g., show a dialog)
          // this.dialog("Error", this.utils.errorMessage(error)); // Assuming you have dialog and utils services
          return throwError(error);
        })
      );
  };

  /**
   * Retrieve a list of assistants from the API
   * @param publishedOnly - Optional flag to retrieve only published assistants
   * @returns 
   */
  getAssistants = (publishedOnly: boolean = false): Observable<Assistant[]> => {
    const clientId = 'gdco_app';
    const queryParams = `client_id=${clientId}${publishedOnly ? '&is_published=true' : ''}`;
    const endpoint = `${this._urlBase}/assistants/?${queryParams}`;

    // Retrieve and force no caching
    return this._http
      .get<Assistant[]>(endpoint, { observe: 'response', headers: { 'Cache-Control': 'no-cache' } })
      .pipe(
        map(response => {
          const data = response.body;
          if (!data) {
            throw new Error('Assistants not found');
          }
          const assistants = data.map((assistantData: Assistant) => new Assistant(assistantData));
          return assistants;
        }),
        catchError(error => {
          // If error is a DG access error
          if (error instanceof Error && error.message === NO_DG_ACCESS_ERROR) {
            throw error;
          }
          // dialog("Error", utils.errorMessage(error, "There was an error populating assistants."));
          throw error; // Optionally, rethrow the error to handle it at the caller
        })
      );
  };

  /**
   * Send feedback to the API
   * @param isPositive - Flag indicating whether the feedback is positive or negative
   * @param feedback - The feedback to send
   * @returns Observable<void>
   */
  sendMessageFeedback(feedback: AssistantMessageFeedback): Observable<void> {
    const endpoint = `${this._urlBase}/message/feedback`;

    return this._http
      .post<void>(endpoint, feedback, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        observe: 'response'
      })
      .pipe(
        map(() => { }),
        catchError(error => {
          return throwError(error);
        })
      );
  }
}
