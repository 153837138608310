import { Component, Input } from '@angular/core';

@Component({
  selector: 'assistant-chat-markdown',
  templateUrl: './assistant-chat-markdown.component.html',
  styleUrls: ['./assistant-chat-markdown.component.scss']
})
export class AssistantChatMarkdownComponent {
  @Input() content!: string;

  hasMarkdown = (): boolean => {
    const markdownRegex = /([*_~`#{1,6}>\[\]()-+.\n])/;
    return markdownRegex.test(this.content);
  }
}
