<div fxFill fxLayout="column" class="gdco-sidenav-panel-container">
  <div fxLayout="row" fxLayoutAlign="stretch center" class="gdco-sidenav-header-row">
    <ng-container *ngIf="label; else sidenavHeader">
      <h4>{{ label }}</h4>
      <div fxFlex></div>
    </ng-container>
    <ng-template #sidenavHeader>
      <ng-content select="gdco-sidenav-header"></ng-content>
    </ng-template>

    <button
      *ngIf="!hideCloseButton"
      [gdcoAutoFocus]="autoFocusCloseButton"
      [gdcoAutoFocusTick]="autoFocusCloseButtonTick"
      mat-icon-button
      (click)="close.emit()"
      title="Close"
    >
      <mat-icon>clear</mat-icon>
    </button>
  </div>

  <ng-content></ng-content>

  <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>

  <ng-content select="gdco-sidenav-footer"></ng-content>
</div>
