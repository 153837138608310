import { Component, Input } from '@angular/core';
import { AssistantMessage } from '../models';

@Component({
  selector: 'assistant-user-message',
  templateUrl: './assistant-user-message.component.html'
})
export class AssistantUserMessageComponent {
  @Input() message!: AssistantMessage;
}
