import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { AssistantPoller } from '@gdco/reference-systems/gdco-service';
import { Subject } from 'rxjs';
import { AssistantStorageService } from '../../assistant/assistant-storage.service';
import { AssistantChatAccessor } from './assistant-chat.accessor';
import {
  Assistant, AssistantChatMessage, AssistantChatRequest, AssistantChatResponse, AssistantMessage,
  AssistantMessageFeedback,
  Round, RoundRole, Session
} from '../models';

@Injectable({ providedIn: 'root' })
export class AssistantChatService {
  // We use Subject instead of BehaviorSubject because we want to only emit new messages
  // History messages can be retrieved from the session
  private messagesSubject = new Subject<AssistantChatMessage[]>();
  messages$ = this.messagesSubject.asObservable();

  private _session?: Session;
  private _assistant?: Assistant;

  constructor(
    private router: Router,
    private assistantAccessor: AssistantChatAccessor,
    private assistantPoller: AssistantPoller,
    private assistantStorageService: AssistantStorageService
  ) { }

  /**
   * Get the current session
   */
  get session(): Session {
    return this._session;
  }

  /**
   * Get the current assistant
   */
  get assistant(): Assistant {
    return this._assistant;
  }

  /**
   * Set the session
   * @param session 
   */
  // eslint-disable-next-line @typescript-eslint/adjacent-overload-signatures
  set session(session: Session) {
    this._session = session;
  }

  /**
   * Store the assistant
   */
  storeSession(session: Session, expirationMins: number = 10): void {
    this.assistantStorageService.save({ key: `dana_session_${session.session_id}`, data: session, expirationMins: expirationMins });
  }

  /**
   * Set the assistant
   */
  removeSession(sessionId: string): void {
    this.assistantStorageService.remove(`dana_session_${sessionId}`);
    this.assistantStorageService.remove(`dana_session_${sessionId}_messages`);
  }

  /**
   * Set the assistant
   */
  // eslint-disable-next-line @typescript-eslint/adjacent-overload-signatures
  set assistant(assistant: Assistant) {
    this._assistant = assistant;
  }

  /**
 * Sends a message to the assistant and being polling for a response
 * @param message - The message to send
 * @param sessionId - The session ID to use for sending the message (if available)
 * @returns Promise<string | void> - Returns the sessionId if it's a new session, otherwise void
 * @throws Error
 * 
 * @example
 * sendMessage('Hello', currentSessionId).then(sessionId => {
 *   if (sessionId) {
 *     console.log('New session ID:', sessionId);
 *   }
 * });
 */
  async sendMessage(message: string | AssistantMessage, shortName: string, sessionId?: string): Promise<string> {
    const isNewSession = !sessionId;

    try {
      // If message is a string, use it directly; otherwise, check if it's an instance of AssistantMessage
      const messageText = typeof message === 'string' ? message : message.text;
      const messageToSend = new AssistantChatRequest({ user_message: messageText });

      // If sessionId is not specified, then create a new session passing messageText to be used
      // as basis for the session name
      if (!sessionId) {
        const sessionResponse = await this.assistantAccessor.createSession(shortName, messageText).toPromise();
        sessionId = sessionResponse.session.session_id;
      }

      // Send the message
      await this.assistantAccessor.sendMessage(shortName, sessionId, messageToSend).toPromise();

      // Define the polling logic for retrieving the assistant response 
      const pollingLogic = async (): Promise<AssistantChatResponse> => {
        const chatResponse = await this.assistantAccessor.getChatResponse(shortName, sessionId).toPromise();
        return chatResponse;
      };

      const exitCondition = (result: AssistantChatResponse): boolean => !result.is_pending;

      const options = {
        pollingInterval: 5000,
        pollingDuration: 120000,
        pollingLogic: pollingLogic,
        exitCondition: exitCondition,
        initAction: () => {
          // Optional: Set loading state
          // this.assistantPoller.loadingIndicator$.next(true);
        },
        terminalAction: () => {
          // Optional: Clear loading state
          // this.assistantPoller.loadingIndicator$.next(false);
        },
      };

      // Step 3: Start polling
      const pollingPromise = this.assistantPoller.createPollingPromise(options);

      // Handle the result of polling
      await pollingPromise.promise
        .then((pollingResult: AssistantChatResponse) => {
          this.session = pollingResult.session;
          this.storeSession(this.session);

          const serverOrAssistantMessages = this.getRecentResponseMessagesFromSession(pollingResult.session);

          // Assuming you have a method to add multiple messages
          if (serverOrAssistantMessages.length > 0) {
            this.addMessages(serverOrAssistantMessages);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    } catch (error) {
      console.error(error);
      throw error;
    }

    // Return sessionId if it's a new session
    if (isNewSession) {
      return sessionId;
    }
  }

  /**
   * Retrieve an assistant from the API
   * @returns Observable<Assistant>
   */
  async getAssistant(shortname: string, setAssistant: boolean = false): Promise<Assistant> {
    const storageKey = `dana_assistant_${shortname}`;
    let result: Assistant = this.assistantStorageService.load(storageKey);
    if (!result) {
      const assistant$ = this.assistantAccessor.getAssistant(shortname);
      result = await assistant$.toPromise();
    }

    if (setAssistant) {
      this.assistant = result;
    }

    this.assistantStorageService.save({ key: storageKey, data: result, expirationMins: 10 });
    return result;
  }

  /**
  * Retrieve all assistants from the API
  * @returns Promise<Assistant[]>
  */
  getAssistants = async (publishedOnly: boolean = false): Promise<Assistant[]> => {
    const storageKey = `dana_assistant_list_${publishedOnly ? 'published' : 'all'}`;
    let result: Assistant[] = this.assistantStorageService.load(storageKey);
    if (!result) {
      const data$ = this.assistantAccessor.getAssistants(publishedOnly);
      result = await data$.toPromise();
      this.assistantStorageService.save({ key: storageKey, data: result, expirationMins: 10 });
    }
    return result;
  };

  /**
   * Retrieve session from the API
   * @param sessionId - The ID of the session to retrieve
   * @returns Observable<Session>
   */
  async getSession(sessionId: string, setSession: boolean = false): Promise<Session> {
    const storageKey = `dana_session_${sessionId}`;
    let result: Session = this.assistantStorageService.load(storageKey);
    if (!result) {
      const session$ = this.assistantAccessor.getSession(sessionId);
      result = await session$.toPromise();
      if (setSession) {
        this.session = result;
        this.storeSession(this.session);
      } else {
        this.assistantStorageService.save({ key: storageKey, data: result, expirationMins: 10 });
      }
    }
    return result;
  }

  /**
  * Returns all messages from the session
  * @param session - The session object containing dialog rounds
  * @returns AssistantChatMessage[]
  */
  getMessagesFromSession(session: Session): AssistantChatMessage[] {
    const result: AssistantChatMessage[] = [];
    if (session?.dialog?.rounds) {
      session.dialog.rounds.forEach(round => {
        const message = this.createAssistantMessage(round); result.push(message);
      });
    }
    return result;
  }

  /**
  * Parse the most recent messages from the session that are responses from the server
  * @param session 
  * @returns 
  */
  getRecentResponseMessagesFromSession = (session: Session): AssistantChatMessage[] => {
    const rounds: Round[] = session.dialog.rounds;
    const result = new Array<AssistantChatMessage>();

    const lastUserMessageIndex = (() => {
      for (let i = rounds.length - 1; i >= 0; i--) {
        if (rounds[i].role === RoundRole.User) {
          return i;
        }
      }
      return -1;
    })();

    if (lastUserMessageIndex !== -1) {
      const messageTypes = [RoundRole.Assistant, RoundRole.System];

      // Get messages after the last user message
      const messagesAfterLastUser = rounds.slice(lastUserMessageIndex + 1);

      // Filter messages of type system or assistant
      const systemOrAssistantMessages = messagesAfterLastUser.filter(round => messageTypes.includes(round.role));

      // Add the server responses to the state
      systemOrAssistantMessages.map((round): void => {
        const message = this.createAssistantMessage(round);
        result.push(message);
      });
    }

    return result;
  };

  /**
   * Create an assistant message from the round
   * @param round Round returned from the server
   * @returns 
   */
  createAssistantMessage = (round: Round): AssistantChatMessage => {
    // General assistant message data
    const messageData: any = {
      text: round.text,
      messageRole: round.role,
      messageFormat: 'markdown',
      timeStamp: new Date(round.timestamp),
      displayHeader: true
    };

    // System message data
    if (round.role === 'system') {
      if (round.metadata && round.metadata.function_call && round.metadata.function_call.parameters) {
        const parameters = round.metadata.function_call.parameters;
        const keys = Object.keys(parameters);
        // Find the first key that is not title
        const otherKey = keys.find(key => key !== 'title');
        if (otherKey) {
          const roundText = parameters[otherKey];
          messageData.text = roundText;
          // Mermaid system message data
          if (otherKey.includes('mermaid') || round.metadata.function_call.function_name.includes("mermaid")) {
            messageData.messageFormat = 'mermaid';
            // Kusto query system message data
          } else if (otherKey.includes('query') || round.metadata.function_call.function_name.includes("DataGalaxy")) {
            messageData.messageFormat = 'query';
            messageData.kustoLink = round.metadata.link;
            messageData.rowCount = round.metadata.row_count;
            messageData.colCount = round.metadata.col_count;
            messageData.rowExceedMax = round.metadata.row_exceed_max;
            messageData.colExceedMax = round.metadata.col_exceed_max;
            messageData.result = round.metadata.function_call.result.query_result;
            messageData.error = round.metadata.error;
          }
        }
      }
    }
    const message = new AssistantMessage(messageData);
    return message;
  };

  /**
   * Add a message to the tracking subject
   * @param message 
   */
  private addMessage(message: AssistantChatMessage): void {
    this.messagesSubject.next([message]);
  }


  /**
 * Add multiple messages to the tracking subject
 * @param messages 
 */
  private addMessages(messages: AssistantChatMessage[]): void {
    this.messagesSubject.next(messages);
  }

  /**
   * Send feedback for an assistant message
   * @param feedback 
   */
  async sendMessageFeedback(feedback: AssistantMessageFeedback): Promise<void> {
    try {
      await this.assistantAccessor.sendMessageFeedback(feedback).toPromise();
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  /**
 * Get the ticketId from the current URL
 */
  get ticketId(): string {
    let ticketId = '';
    try {
      // parse the ticketId
      const parsedUrl: UrlTree = this.router.parseUrl(this.router.url);
      const segments = parsedUrl.root.children['primary']?.segments.map(segment => segment.toString());
      ticketId = (segments && segments.length >= 3 && segments[0] === 'tasks' && segments[1] === 'details' && segments[2] !== '') ?
        segments[2] : 'Unknown';
    } catch (e) {
      ticketId = 'Unknown';
    }

    return ticketId;
  }
}