<div class="breadcrumbs-container">
  <ng-container *ngFor="let breadcrumb of breadcrumbs || _segments; let last = last">
    <ng-container *ngIf="!last; else lastBreadcrumb">
      <!-- Breadcrumb with link -->
      <a
        *ngIf="breadcrumb.url; else noLink"
        [routerLink]="breadcrumb.url"
        [queryParams]="breadcrumb.queryParams || {}"
        [queryParamsHandling]="breadcrumb.preserveQueryParams ? 'merge' : ''"
      >
        <ng-container
          *ngTemplateOutlet="breadcrumbSegment; context: { $implicit: breadcrumb, link: true }"
        ></ng-container>
      </a>

      <!-- Breadcrumb without link -->
      <ng-template #noLink>
        <ng-container
          *ngTemplateOutlet="breadcrumbSegment; context: { $implicit: breadcrumb, link: false }"
        ></ng-container>
      </ng-template>

      <span class="separator"> {{ separator }} </span>
    </ng-container>

    <!-- Last breadcrumb template -->
    <ng-template #lastBreadcrumb>
      <strong>
        <ng-container
          *ngTemplateOutlet="breadcrumbSegment; context: { $implicit: breadcrumb, link: false }"
        ></ng-container>
      </strong>
    </ng-template>
  </ng-container>
</div>

<!-- Breadcrumb segment template -->
<ng-template #breadcrumbSegment let-breadcrumb let-link="link">
  <!-- Class and attributes are used by the segment harness. -->
  <span class="gdco-breadcrumb-segment" [attr.url]="breadcrumb.url" [attr.link]="link">
    <ng-container *ngIf="_isSegmentComponent(breadcrumb); else breadcrumbLabel">
      <ng-container *ngTemplateOutlet="breadcrumb._template"></ng-container>
    </ng-container>
    <ng-template #breadcrumbLabel>{{ breadcrumb.label }}</ng-template>
  </span>
</ng-template>
