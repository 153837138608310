<!-- <div class="dana-chat-message-container"> -->
<div class="dana-chat-avatar">
  <div class="dana-chat-avatar-container">
    <bot-icon></bot-icon>
  </div>
</div>
<div class="dana-chat-message">
  <div class="dana-chat-loader-container">
    <svg id="dots" width="50px" height="21px" viewBox="0 0 132 58" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g stroke="none" fill="none">
        <g id="dana-chat-loader" fill="#fff">
          <circle id="dana-chat-loader-dot1" cx="25" cy="30" r="13"></circle>
          <circle id="dana-chat-loader-dot2" cx="65" cy="30" r="13"></circle>
          <circle id="dana-chat-loader-dot3" cx="105" cy="30" r="13"></circle>
        </g>
      </g>
    </svg>
  </div>
  <div class="dana-chat-message-arrow"></div>
</div>
<!-- </div> -->