import { AssistantFile } from "./assistant-file";
import { AssistantTool } from "./assistant-tool";

/**
 * Model for Assistant
 */
export class Assistant {
    assistant_id: string;
    id?: string;
    name: string;
    shortname: string;
    greeting: string;
    description: string;
    is_published: boolean;
    instructions: string;
    tools: AssistantTool[];
    files: AssistantFile[];
    metadata: any;

    displayDisclaimer: boolean;
    disclaimer: string;

    constructor(partial?: Partial<Assistant>) {
        this.assistant_id = partial?.assistant_id ?? partial?.id ?? '';
        this.name = partial?.name ?? '';
        this.shortname = partial?.shortname ?? '';
        this.greeting = partial?.greeting ?? '';
        this.description = partial?.description ?? '';
        this.is_published = partial?.is_published ?? false;
        this.instructions = partial?.instructions ?? '';
        this.tools = partial?.tools?.map(toolData => new AssistantTool(toolData)) ?? [];
        this.files = partial?.files ?? [];
        this.metadata = partial?.metadata ?? {};
        this.is_published = partial?.is_published ?? false;

        this.displayDisclaimer = partial?.displayDisclaimer ?? true;
        this.disclaimer = partial?.disclaimer ?? 'Responses are AI generated. Please use caution and follow standard safety practices.';
    }
}