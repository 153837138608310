import { formatMessage } from '@coie/application-shell';
import { Extension, ExtensionAction } from '@coie/application-shell';

export class OutageExtension extends Extension {
    name: string;
    svgIconPath: string;
    action: ExtensionAction;
    navigateUrl: string;
    tooltip: string;
    dropdownItems?: { label: string; link: string }[];

    constructor() {
        super();
        this.name = 'outage';
        this.svgIconPath = 'assets/icons/shellv2/bug_20_regular.svg';
        this.action = 'Navigate';
        this.navigateUrl = '/outages';
        this.tooltip = formatMessage('outageCenter');
    }

    async render(): Promise<string> {
        return this.getLink();
    }
}
