import { Component, Input } from '@angular/core';
import { AssistantMessage } from '../models';

@Component({
  selector: 'assistant-response-message',
  templateUrl: './assistant-response-message.component.html'
})
export class AssistantResponseMessageComponent {
  @Input() message!: AssistantMessage;
}

