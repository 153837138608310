import { Extension, ExtensionAction, formatMessage } from '@coie/application-shell';
import styles from './user-extension.styles';

export class UserExtension extends Extension {
    name: string;
    svgIconPath: string;
    action: ExtensionAction;
    navigateUrl?: string;
    dropdownItems: { label: string; link: string }[];
    tooltip: string;

    constructor() {
        super();
        this.name = 'user';
        this.svgIconPath = 'assets/icons/shellv2/person_circle_20_regular.svg';
        this.action = 'DropdownMenu';
        this.dropdownItems = [];
        this.tooltip = '';
    }

    async render(): Promise<string> {
        const styleContent = styles();
        return `
            <div class="user-extension">
            <div class="icon" id="${this.name}-icon">${await this.getIcon()}</div>
                <div class="dropdown-menu" id="${this.name}-dropdown">
                    <style>${styleContent}</style>
                    <div class="header">
                        <div class="title">Microsoft</div>
                        <a id="mectrl_body_signOut" class="sign-out" 
                            aria-label="Sign out of this account" role="button" href=${this.dropdownItems.find(item => item.label === 'signout')?.link || 'https://msazure.visualstudio.com/'} target="_top">
                            ${formatMessage('signOut')}
                        </a>
                    </div>
                    <div class="profile">
                        <div class="avatar" style="background-image: url('${this.dropdownItems.find(item => item.label === 'avatorLink')?.link || ''}');">
                            ${!this.dropdownItems.find(item => item.label === 'avatorLink') ? (this.dropdownItems.find(item => item.label === 'abbr')?.link || 'UU') : ''}
                        </div>
                        <div class="details">
                            <div class="name">${this.dropdownItems.find(item => item.label === 'displayName')?.link || 'not found'}</div>
                            <div class="email">${this.dropdownItems.find(item => item.label === 'email')?.link || 'not found'}</div>
                            <div class="account-link">
                                <a href="https://myaccount.microsoft.com/" target="_blank">
                                    ${formatMessage('viewAccount')}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        `;
    }
}
