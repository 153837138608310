import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {
    IShellApplicationBar,
    shellApplicationBarSubscription,
    setShellApplicationBar,
    setShellTheme,
    IShellTheme,
    ThemeDefaultState,
    ThemeState,
    ShellAPI
} from '@coie/application-shell';
import { CommonApplicationBar } from '../../../application-shell-behavior/src/common-application-bar';

@Injectable({
  providedIn: 'root'
})
export class Shellv2Service {
  private themeSubject: BehaviorSubject<IShellTheme>;
  public theme$: Observable<IShellTheme>;

  private applicationBarSubject: BehaviorSubject<IShellApplicationBar>;
  public applicationBar$: Observable<IShellApplicationBar>;

  constructor() {
    this.applicationBarSubject = new BehaviorSubject<IShellApplicationBar>(CommonApplicationBar);
    this.applicationBar$ = this.applicationBarSubject.asObservable();

    const themeDefaultState: ThemeState = ThemeDefaultState;
    this.themeSubject = new BehaviorSubject<IShellTheme>(
      {
        themeName: themeDefaultState.themeName,
        theme: themeDefaultState.theme
      }
    );
    this.theme$ = this.themeSubject.asObservable();

    shellApplicationBarSubscription((applicationBar: IShellApplicationBar) => {
      this.applicationBarSubject.next(applicationBar);
    });
  }

  getApplicationBar(): IShellApplicationBar {
    return this.applicationBarSubject.getValue();
  }

  useCustomShellApplicationBarEffect(applicationBar: IShellApplicationBar): void {
    this.applicationBarSubject.next(applicationBar);
    setShellApplicationBar(applicationBar);
  }

  useCustomShellThemeEffect(theme: IShellTheme): void {
    this.themeSubject.next(theme);
    setShellTheme(theme);
  }

  useShellAPI() {
    return ShellAPI;
  }
}
