<div *ngIf="showLogin" class="login-content">
  <mat-card class="login-card">
    <div class="login-header">Sign In</div>
    <div class="login-subheader">Global Data Center Operations (GDCO) App</div>
    <button
      *ngFor="let option of loginOptions"
      mat-flat-button
      color="accent"
      class="login-button"
      (click)="onLoginClicked(option)"
    >
      {{ option.displayName.indexOf('account') > 0 ? '@' : '' }}{{ option.displayName }}
    </button>
  </mat-card>
</div>
