<gdco-label *ngIf="_formFieldLabel || label" class="label" [required]="required">
  <ng-container *ngIf="_formFieldLabel; else defaultLabelTemplate">
    <ng-container *ngTemplateOutlet="_formFieldLabel.template"></ng-container>
  </ng-container>
  <ng-template #defaultLabelTemplate>
    {{ label }}
  </ng-template>
</gdco-label>
<mat-radio-group [formControl]="formControl" [required]="required">
  <mat-radio-button [value]="true" class="yes-option">Yes</mat-radio-button>
  <mat-radio-button [value]="false">No</mat-radio-button>
</mat-radio-group>
