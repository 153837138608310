import { Injectable, OnDestroy, Optional, SkipSelf } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';

import { GdcoNotification, GdcoNotifications } from '@gdco/core';

import { GdcoNotificationComponent } from './notification.component';

@Injectable()
export class GdcoNotificationsListenerService implements OnDestroy {
  private _activeNotification: GdcoNotification | null = null;

  private _notificationsSub: Subscription = Subscription.EMPTY;
  private _detailsDialogOpenedSub: Subscription = Subscription.EMPTY;
  private _detailsDialogClosedSub: Subscription = Subscription.EMPTY;
  private _snackBarClosedSub: Subscription = Subscription.EMPTY;

  constructor(
    private _snackBar: MatSnackBar,
    private _notifications: GdcoNotifications,
    @Optional() @SkipSelf() private _parent: GdcoNotificationsListenerService
  ) {}

  init(): void {
    if (this._parent) {
      // Only init the root object for singelton
      return;
    }

    this._notificationsSub = this._notifications.new.subscribe(notification => {
      if (this._activeNotification == null && !notification.gdcoDialogComponentOptions?.showDialog) {
        const previousFocusedElement = document.activeElement as HTMLElement | null;
        let detailsDialogOpened = false;

        const snackBarRef = this._snackBar.openFromComponent(GdcoNotificationComponent, {
          duration: 3000,
          data: notification,
          panelClass: ['grey-snackbar']
        });

        const restoreFocus = () => {
          if (notification.autoFocusDetailsButton === false) {
            // If no auto focus, won't restore focus
            return;
          }

          if (previousFocusedElement != null && previousFocusedElement.focus != null) {
            previousFocusedElement.focus();
          }
        };

        this._detailsDialogOpenedSub.unsubscribe();
        this._detailsDialogClosedSub.unsubscribe();
        this._snackBarClosedSub.unsubscribe();

        const notificationSnackBarComponent = snackBarRef.instance;
        this._detailsDialogOpenedSub = notificationSnackBarComponent.detailsDialogOpened.subscribe(
          () => (detailsDialogOpened = true)
        );
        this._detailsDialogClosedSub = notificationSnackBarComponent.detailsDialogClosed.subscribe(() =>
          restoreFocus()
        );
        this._snackBarClosedSub = notificationSnackBarComponent.closed.subscribe(() => snackBarRef.dismiss());

        snackBarRef.afterDismissed().subscribe(() => {
          this._activeNotification = null;

          // If the details dialog is opened, focus will be restored after dialog is closed instead of snack bar dismissed
          if (!detailsDialogOpened && document.activeElement === document.body) {
            restoreFocus();
          }
        });
      }
    });
  }

  ngOnDestroy() {
    this.destory();
  }

  destory() {
    this._notificationsSub.unsubscribe();
    this._detailsDialogOpenedSub.unsubscribe();
    this._detailsDialogClosedSub.unsubscribe();
    this._snackBarClosedSub.unsubscribe();
  }
}
