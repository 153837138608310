import { Component, Input } from '@angular/core';

@Component({
  selector: 'assistant-chat-query',
  templateUrl: './assistant-chat-query.component.html',
  styleUrls: ['./assistant-chat-query.component.scss']
})
export class AssistantChatQueryComponent {
  @Input() link: string | undefined;
  @Input() id!: string;
  @Input() rowCount: number | undefined;
  @Input() colCount: number | undefined;
  @Input() rowExceedMax: boolean | undefined;
  @Input() colExceedMax: boolean | undefined;
  @Input() result: object[] = [];
  @Input() error: string | undefined;

  // Generate the appropriate query message based on the inputs
  get queryMessage(): string {
    if (this.rowExceedMax && this.colExceedMax) {
      return `Displaying the top 20 rows of ${this.rowCount} rows and the first 7 columns of ${this.colCount} columns. To view the complete table:`;
    } else if (this.rowExceedMax) {
      return `Displaying the top 20 rows of ${this.rowCount} rows. To view the complete table:`;
    } else if (this.colExceedMax) {
      return `Displaying the first 7 columns of ${this.colCount} columns. To view the complete table:`;
    } else {
      return 'To run this query:';
    }
  }
}
