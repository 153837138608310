import { Component, Input, ElementRef, OnInit, OnDestroy, ViewChild } from '@angular/core';
import mermaid from 'mermaid';

mermaid.initialize({});

@Component({
  selector: 'assistant-chat-mermaid',
  templateUrl: './assistant-chat-mermaid.component.html'
})
export class AssistantChatMermaidComponent implements OnInit, OnDestroy {
  @Input() source!: string;
  @Input() id!: string;
  @ViewChild('mermaidContainer', { static: true }) mermaidContainer!: ElementRef;

  svgContent: string = '';

  ngOnInit() {
    this.drawDiagram();
  }

  async drawDiagram() {
    const element = this.mermaidContainer.nativeElement;

    try {
      // Render the diagram using the mermaid.renderAsync method, which returns a string (the SVG)
      const svg = await mermaid.renderAsync(`${this.id}-diagram-svg`, this.source);
      this.svgContent = svg;
      element.innerHTML = this.svgContent;  // Directly insert the SVG into the DOM
    } catch (e) {
      this.svgContent = this.source; // Fallback to the source if diagram rendering fails
      element.innerHTML = this.svgContent;
    }
  }

  ngOnDestroy() {
    // Clean up Mermaid's internal state when the component is destroyed
    mermaid.mermaidAPI.reset();
  }
}
