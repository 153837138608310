<ng-template #dialogTemplate>
  <mat-dialog-content class="dialog-content" [attr.tabindex]="tabIndex">
    <div class="dialog-container" [@.disabled]="_animationsDisabled | async">
      <div class="dialog-label">
        <div>
          <ng-container *ngIf="_dialogLabel; else textLabel">
            <ng-container *ngTemplateOutlet="_dialogLabel"></ng-container>
          </ng-container>
          <ng-template #textLabel>
            <h4>{{ label }}</h4>
          </ng-template>
        </div>

        <div class="stretch"></div>

        <button mat-icon-button class="close-button" (click)="close()" title="Close" aria-label="Close">
          <mat-icon class="close-button close-icon">clear</mat-icon>
        </button>
      </div>

        <ng-container *ngIf="_dialogContent; else contentTemplate">
          <ng-container *ngTemplateOutlet="_dialogContent"></ng-container>
        </ng-container>
        <ng-template #contentTemplate>
          <ng-content></ng-content>
        </ng-template>
    </div>
  </mat-dialog-content>
</ng-template>
