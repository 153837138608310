<h2 mat-dialog-title>Choose an Assistant</h2>

<mat-dialog-content class="mat-typography">
  <ng-container *ngIf="assistants && assistants.length > 0; else loading">
    <mat-selection-list #assistantSelectList [multiple]="false">
      <mat-list-option *ngFor="let assistant of assistants" [value]="assistant">
        <h3 matListItemTitle style="font-weight: bold;">{{assistant.name}}</h3>
        <p matListItemLine>
          <span>{{assistant.description}}</span>
        </p>
      </mat-list-option>
    </mat-selection-list>
  </ng-container>

  <!-- Display a loading indicator on startup -->
  <ng-template #loading>
    <div class="loading-prompt">
      <mat-spinner [diameter]="40" class="loader"></mat-spinner>
      <p>Loading...</p>
    </div>
  </ng-template>

</mat-dialog-content>

<mat-dialog-actions class="dialog-actions-right">
  <button mat-button (click)="cancel()">Cancel</button>
  <button mat-raised-button (click)="ok()">Ok</button>
</mat-dialog-actions>