<h4 *ngIf="displayTitle">{{ displayTitle }}</h4>
<div class="query-builder-header-row">
  <div *ngIf="!disabled" class="query-builder-add-button"></div>
  <div *ngIf="!disabled" class="query-builder-remove-button"></div>
  <button
    *ngIf="!disabled"
    class="query-builder-group-button"
    type="button"
    mat-icon-button
    title="Group selected clauses"
    [disabled]="disableGroupSelection"
    (click)="onGroupSelectedClauses()"
  >
    <mat-icon>list</mat-icon>
  </button>
  <div class="query-builder-group-header-item">
    <div *ngFor="let dummy of dummyDepthArray" class="query-builder-group"></div>
  </div>
  <div class="query-builder-header-item query-builder-logical-operator">
    <span>And/Or</span>
  </div>
  <div class="query-builder-header-item query-builder-field">
    <span>Field</span>
  </div>
  <div class="query-builder-header-item query-builder-operator">
    <span>Operator</span>
  </div>
  <div class="query-builder-header-item query-builder-value">
    <span>Value</span>
  </div>
</div>

<form [formGroup]="formGroup" novalidate autocomplete="off">
  <div formArrayName="selectableClauses">
    <div
      class="query-builder-row"
      *ngFor="let clause of selectableClauses.controls; let i = index"
      [formGroupName]="i + scrollWindow.start"
    >
      <div *ngIf="!disabled" class="query-builder-add-button" title="Insert clause">
        <button
          type="button"
          mat-icon-button
          aria-label="Add clause"
          class="query-builder-button"
          (click)="add(i + scrollWindow.start)"
        >
          <mat-icon class="query-builder-button-icon">add</mat-icon>
        </button>
      </div>

      <div *ngIf="!disabled" class="query-builder-remove-button" title="Remove clause">
        <button
          type="button"
          mat-icon-button
          aria-label="Remove clause"
          class="query-builder-button"
          [attr.data-test]="'query-builder-remove:' + i"
          (click)="remove(i + scrollWindow.start)"
        >
          <mat-icon class="query-builder-button-icon">remove</mat-icon>
        </button>
      </div>

      <div *ngIf="!disabled" class="query-builder-item query-builder-checkbox">
        <mat-checkbox aria-label="Select" formControlName="selected" (change)="onSelectionChange()"></mat-checkbox>
      </div>

      <ng-container *ngFor="let dummy of dummyDepthArray; let groupDepth = index">
        <ng-container [ngSwitch]="getGroupIndicator(maxDepth - groupDepth, i + scrollWindow.start)">
          <div *ngSwitchCase="'startHeader'" gdcoBorder="top left" class="query-builder-group">
            <button
              type="button"
              class="query-builder-button"
              mat-icon-button
              aria-label="Ungroup clauses"
              title="Ungroup clauses"
              (click)="onUngroupClauses(getGroup(maxDepth - groupDepth, i + scrollWindow.start))"
              [disabled]="disabled"
            >
              <mat-icon class="query-builder-button-icon">list</mat-icon>
            </button>
          </div>
          <div *ngSwitchCase="'startNormal'" gdcoBorder="top" class="query-builder-group"></div>
          <div *ngSwitchCase="'middle'" gdcoBorder="left" class="query-builder-group"></div>
          <div *ngSwitchCase="'endNormal'" gdcoBorder="bottom" class="query-builder-group"></div>
          <div *ngSwitchCase="'end'" gdcoBorder="bottom left" class="query-builder-group"></div>
          <div *ngSwitchCase="'empty'" class="query-builder-group"></div>
        </ng-container>
      </ng-container>

      <div class="query-builder-item query-builder-logical-operator">
        <mat-form-field subscriptSizing="dynamic" *ngIf="i + scrollWindow.start !== 0" class="query-builder-item query-builder-logical-operator gdco-compact">
          <mat-select
            gdcoMatSelectAccessibility
            formControlName="logicalOperator"
            [attr.data-test]="'query-builder-logical-operator:' + i"
          >
            <mat-option value="and" data-test="query-builder-logical-operator-option:And">And</mat-option>
            <mat-option value="or" data-test="query-builder-logical-operator-option:Or">Or</mat-option>
          </mat-select>
          <mat-error *ngIf="clause.hasError('invalidLogicalOperator')">{{
            clause.getError('invalidLogicalOperator')
          }}</mat-error>
        </mat-form-field>
      </div>

      <mat-form-field subscriptSizing="dynamic" class="query-builder-item query-builder-field gdco-compact">
        <input
          type="text"
          matInput
          aria-label="Field"
          formControlName="field"
          [matAutocomplete]="fieldAutocomplete"
          (focus)="onInputFocus(i + scrollWindow.start)"
          (keydown)="onInputKeydown($event, fieldAutocomplete)"
          [attr.data-test]="'query-builder-field:' + i"
        />
        <mat-error *ngIf="clause.hasError('invalidOption')">{{ clause.getError('invalidOption') }}</mat-error>
        <mat-autocomplete #fieldAutocomplete="matAutocomplete" autoActiveFirstOption="false">
          <ng-container *ngIf="i + scrollWindow.start === focusedIndex && !disabled">
            <mat-option
              *ngFor="let option of filteredOptions[i + scrollWindow.start] | async"
              [value]="option.displayName || option.name"
              [attr.title]="option.displayName || option.name"
              (keydown)="onOptionKeydown($event, fieldAutocomplete)"
              [attr.data-test]="'query-builder-field-option:' + option.displayName"
            >
              {{ option.displayName || option.name }}
            </mat-option>
          </ng-container>
        </mat-autocomplete>
      </mat-form-field>

      <mat-form-field subscriptSizing="dynamic" class="query-builder-item query-builder-operator gdco-compact">
        <mat-select
          gdcoMatSelectAccessibility
          *ngIf="!disabled"
          formControlName="operator"
          aria-label="Operator"
          [attr.data-test]="'query-builder-operator:' + i"
        >
          <mat-option
            *ngFor="let operator of getOperators(clause.value?.field)"
            [value]="operator.value"
            [attr.data-test]="'query-builder-operator-option:' + operator.displayName"
          >
            {{ operator.displayName }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="clause.hasError('invalidOperator')">{{ clause.getError('invalidOperator') }}</mat-error>
        <input *ngIf="disabled" matInput formControlName="operator" aria-label="Operator" />
      </mat-form-field>

      <mat-form-field subscriptSizing="dynamic" *ngIf="disabled" class="query-builder-item stretch query-builder-value gdco-compact">
        <input
          matInput
          aria-label="Value"
          formControlName="value"
          [disabled]="true"
          [attr.data-test]="'query-builder-value:' + i"
        />
      </mat-form-field>

      <ng-container
        *ngIf="!disabled"
        [ngSwitch]="getFieldType(clause.value?.field)"
        class="query-builder-item stretch"
      >
        <mat-form-field subscriptSizing="dynamic" *ngSwitchCase="'string'" class="stretch query-builder-value gdco-compact">
          <input
            type="text"
            matInput
            aria-label="Value"
            formControlName="value"
            [matAutocomplete]="stringValueAutocomplete"
            (focus)="onValueInputFocus(i + scrollWindow.start)"
            (keydown)="onInputKeydown($event, stringAutocomplete)"
            [attr.data-test]="'query-builder-value-string:' + i"
          />
          <mat-error *ngIf="clause.hasError('invalidValue')">{{ clause.getError('invalidValue') }}</mat-error>
          <mat-autocomplete #stringValueAutocomplete="matAutocomplete" autoActiveFirstOption="false">
            <ng-container *ngIf="i + scrollWindow.start === focusedIndex">
              <mat-option
                *ngFor="let option of filteredValues[i + scrollWindow.start] | async"
                [value]="option.value || option"
                (keydown)="onOptionKeydown($event, stringAutocomplete)"
              >
                {{ option.displayName || option }}
              </mat-option>
            </ng-container>
          </mat-autocomplete>
        </mat-form-field>

        <mat-form-field subscriptSizing="dynamic" *ngSwitchCase="'boolean'" class="stretch query-builder-value gdco-compact">
          <mat-select
            gdcoMatSelectAccessibility
            formControlName="value"
            aria-label="Value"
            [attr.data-test]="'query-builder-value-boolean:' + i"
          >
            <mat-option
              *ngFor="let option of booleanOptions"
              [value]="option.value"
              [attr.data-test]="'query-builder-value-option:' + option.displayName"
            >
              {{ option.displayName }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="clause.hasError('invalidValue')">{{ clause.getError('invalidValue') }}</mat-error>
        </mat-form-field>

        <ng-container *ngSwitchCase="'number'">
          <mat-form-field subscriptSizing="dynamic" *ngIf="getValues(clause).length > 0" class="stretch query-builder-value gdco-compact">
            <mat-select
              gdcoMatSelectAccessibility
              formControlName="value"
              aria-label="Value"
              [attr.data-test]="'query-builder-value-number:' + i"
            >
              <mat-option *ngFor="let value of optionsMap[clause.value?.field].values" [value]="value">
                {{ value }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="clause.hasError('invalidValue')">{{ clause.getError('invalidValue') }}</mat-error>
            <mat-error *ngIf="clause.hasError('invalidNumber')">{{ clause.getError('invalidNumber') }}</mat-error>
          </mat-form-field>

          <mat-form-field subscriptSizing="dynamic" *ngIf="getValues(clause).length <= 0" class="stretch query-builder-value gdco-compact">
            <input
              type="number"
              matInput
              aria-label="Value"
              formControlName="value"
              [matAutocomplete]="numberValueAutocomplete"
              (focus)="onValueInputFocus(i + scrollWindow.start)"
              [attr.data-test]="'query-builder-value-number:' + i"
            />
            <mat-error *ngIf="clause.hasError('invalidValue')">{{ clause.getError('invalidValue') }}</mat-error>
            <mat-error *ngIf="clause.hasError('invalidNumber')">{{ clause.getError('invalidNumber') }}</mat-error>
            <mat-autocomplete #numberValueAutocomplete="matAutocomplete">
              <ng-container *ngIf="i + scrollWindow.start === focusedIndex">
                <mat-option *ngFor="let option of filteredValues[i + scrollWindow.start] | async" [value]="option">
                  {{ option }}
                </mat-option>
              </ng-container>
            </mat-autocomplete>
          </mat-form-field>
        </ng-container>

        <mat-form-field subscriptSizing="dynamic" *ngSwitchCase="'enum'" class="stretch query-builder-value gdco-compact">
          <mat-select
            gdcoMatSelectAccessibility
            formControlName="value"
            aria-label="Value"
            [attr.data-test]="'query-builder-value-enum:' + i"
          >
            <mat-option
              *ngFor="let value of _getEnumValues(clause.value?.field); let k = index"
              [value]="value"
              [attr.data-test]="'query-builder-value-enum-option:' + value"
            >
              {{ value }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="clause.hasError('invalidValue')">{{ clause.getError('invalidValue') }}</mat-error>
        </mat-form-field>

        <mat-form-field subscriptSizing="dynamic" *ngSwitchCase="'datetime'" class="stretch query-builder-value gdco-compact">
          <input
            type="text"
            matInput
            aria-label="Value"
            formControlName="value"
            [matAutocomplete]="dateTimeValueAutocomplete"
            (focus)="onValueInputFocus(i + scrollWindow.start)"
            (keydown)="onInputKeydown($event, dateTimeAutocomplete)"
            [attr.data-test]="'query-builder-value-date:' + i"
          />
          <mat-error *ngIf="clause.hasError('invalidValue')">{{ clause.getError('invalidValue') }}</mat-error>
          <mat-autocomplete #dateTimeValueAutocomplete="matAutocomplete" autoActiveFirstOption="false">
            <ng-container *ngIf="i + scrollWindow.start === focusedIndex">
              <mat-option
                *ngFor="let option of filteredValues[i + scrollWindow.start] | async"
                [value]="option.value"
                (keydown)="onOptionKeydown($event, dateTimeAutocomplete)"
                [attr.data-test]="'query-builder-value-date-option:' + option.value"
              >
                {{ option.displayName }}
              </mat-option>
            </ng-container>
          </mat-autocomplete>
        </mat-form-field>

        <mat-form-field subscriptSizing="dynamic" *ngSwitchDefault class="stretch query-builder-value gdco-compact">
          <input matInput aria-label="Value" [disabled]="true" [attr.data-test]="'query-builder-value-default:' + i" />
        </mat-form-field>
      </ng-container>
    </div>
  </div>
</form>

<div *ngIf="!disabled && isReady" class="query-builder-add-button">
  <button
    type="button"
    title="Add new clause"
    aria-label="Add new clause"
    class="query-builder-button"
    mat-icon-button
    data-test="query-builder-add"
    (click)="addEndClause()"
  >
    <mat-icon class="query-builder-button-icon">add</mat-icon>
  </button>
</div>
