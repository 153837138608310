<div *ngIf="opened$ | async" class="banner gdco-panel-contrast" style="white-space: pre-wrap">
  <div fxLayout="row" fxLayoutAlign="center" fxLayoutGap="16px">
    <div fxFlex>
      <div *ngFor="let message of messages$ | async; let last = last" fxLayout="column">
        <div
          fxLayout="row"
          fxLayoutGap="8px"
          [ngClass]="{ 'message-list-divider': message.isMessageList }"
          class="message-divider"
        >
          <mat-icon *ngIf="message.id !== 'service-outage'" class="outage-info-icon">info</mat-icon>
          <div *ngIf="isSingleOutageMessage(message)" class="info-header">
            <mat-icon class="outage-info-icon">info</mat-icon>
            <h3>Service outage notification</h3>
          </div>
          <div *ngIf="message.id === 'service-outage' && message.isMessageList" class="info-header">
            <mat-icon class="outage-info-icon">info</mat-icon>
            <h3>Notification of multiple service outages</h3>
          </div>
          <gdco-sanitize
            [ngClass]="{ 'title-line': isSingleOutageMessage(message) }"
            [html]="message.message"
          ></gdco-sanitize>
          <button
            *ngIf="!!message.button"
            (click)="handleBannerClick(message.button.link)"
            mat-raised-button
            color="accent"
            class="message-button"
          >
            {{ message.button.displayText }}
          </button>
        </div>
      </div>
    </div>
    <div fxLayout="column">
      <button mat-icon-button aria-label="Close" class="close-button" (click)="close()">
        <mat-icon class="close-button close-icon">clear</mat-icon>
      </button>
      <div fxFlex></div>
    </div>
  </div>
</div>
