<div
  class="tooltip-container"
  role="tooltip"
  (mouseenter)="onMouseOver()"
  (mouseleave)="onMouseLeave()"
  (click)="onClick()"
  (keydown.enter)="onEnter()"
  cdk-overlay-origin
  [attr.tabindex]="focusable ? '0' : '-1'"
  #tooltipHost="cdkOverlayOrigin"
>
  <ng-content></ng-content>
</div>

<ng-template cdk-portal #tooltipTemplate="cdkPortal">
  <div
    cdkTrapFocus
    class="mat-dialog-container mat-menu-panel gdco-tooltip-content"
    (mouseover)="onContentMouseOver()"
    (mouseleave)="onContentMouseLeave()"
    gdcoElevation="8"
  >
    <gdco-tooltip-content *ngIf="_tooltipContentTemplate; else contentSelector">
      <ng-container *ngTemplateOutlet="_tooltipContentTemplate"></ng-container>
    </gdco-tooltip-content>
    <ng-template #contentSelector>
      <ng-content select="gdco-tooltip-content"></ng-content>
    </ng-template>
  </div>
</ng-template>
