<div>
  <div *ngIf="hasPhyNetOpticalData" class="text-frame" fxLayout="row" fxLayoutAlign="start" fxLayoutGap="2px">
    <ng-container *ngTemplateOutlet="phynetContributorTemplate; context: { message: message }"></ng-container>
  </div>

  <div *ngIf="hasLearnCard" class="text-frame" fxLayout="row" fxLayoutAlign="start" fxLayoutGap="2px">
    <ng-container *ngTemplateOutlet="learnCardTemplate; context: { learnCard: message.LearnCard }"></ng-container>
  </div>

  <div *ngIf="hasTicketInsights" class="text-frame" fxLayout="row" fxLayoutAlign="start" fxLayoutGap="2px">
    <ng-container *ngTemplateOutlet="ticketInsightsTemplate; context: { message: message }"></ng-container>
  </div>

  <div *ngIf="hasTicketStats" class="text-frame" fxLayout="row" fxLayoutAlign="start" fxLayoutGap="2px">
    <ng-container *ngTemplateOutlet="ticketStatsTemplate; context: { message: message }"></ng-container>
  </div>

  <div *ngIf="hasAssetHistory && hasAssetEvents" class="text-frame" fxLayout="row" fxLayoutAlign="start"
    fxLayoutGap="2px">
    <ng-container *ngTemplateOutlet="assetHistoryTemplate; context: { message: message }"></ng-container>
  </div>
</div>

<!--
  Template for input buttons for common styling; it will default to using getAriaLabel to get tooltype text and float-right class on the button.
  However, these can be overridden by passing in the buttonClass and getTooltipText properties.
 -->
<ng-template #infoButtonTemplate let-tooltipText let-cardType="cardType" let-getTooltipText="getTooltipText"
  let-buttonClass="buttonClass || 'float-right'">
  <button role="button" [class]="buttonClass" mat-icon-button
    [matTooltip]="getTooltipText ? getTooltipText(cardType) : getAriaLabel(cardType)"
    [attr.aria-label]="getTooltipText ? getTooltipText(cardType) : getAriaLabel(cardType)">
    <mat-icon>info</mat-icon>
  </button>
</ng-template>

<!-- Feedback template -->
<ng-template #feedbackTemplate let-cardType="cardType">
  <div class="feedback" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="1px">
    <p>Was the response helpful?</p>
    <button mat-icon-button aria-label="feedback" matTooltip="Thumb Up" (click)="openDialog(true, cardType)">
      <mat-icon>thumb_up</mat-icon>
    </button>
    <button mat-icon-button aria-label="feedback" matTooltip="Thumb Down" (click)="openDialog(false, cardType)">
      <mat-icon>thumb_down</mat-icon>
    </button>
  </div>
</ng-template>

<!--
   Template for ticket insights; note that if hasTicketInsights is true, then hasSimilarTickets and hasOverallSummary will also be true
-->
<ng-template #ticketInsightsTemplate let-message="message">
  <mat-card appearance="elevated" class="message">
    <!-- Display the header outside of the markdown; note that we hide the first child in the markdown if it's an h2-->
    <h2 class="assistant-flex-container">
      <b>Ticket Insights</b>
      <ng-container
        *ngTemplateOutlet="infoButtonTemplate; context: { cardType: AssistantCardType.OverallSummary }"></ng-container>
    </h2>
    <div *ngIf="hasOverallSummary">
      <gdco-markdown class="content hide-first-header" [markdown]="message.OverallSummary"></gdco-markdown>
    </div>

    <div *ngIf="hasSimilarTickets">
      <h4 id="summary-of-referenced-tickets">Reference tickets used for this analysis:</h4>
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>{{ getSimilarTicketsPanelTitle(message) }}</mat-panel-title>
            <mat-panel-description>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <div class="button-row">
            <button mat-button (click)="accordion.openAll()">Expand All</button>
            <button mat-button (click)="accordion.closeAll()">Collapse All</button>
          </div>
          <mat-accordion #accordion="matAccordion" class="accordion-headers" multi>
            <mat-expansion-panel *ngFor="let ticket of message.SimilarTickets">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <span>Ticket</span>
                  <a style="margin-left: 1em;" target="_blank"
                    href="/tasks/details/{{ticket.TicketId}}">{{ticket.TicketId}}</a>
                </mat-panel-title>
                <mat-panel-description>
                  <!-- <mat-icon>library_books</mat-icon> -->
                </mat-panel-description>
              </mat-expansion-panel-header>
              {{ticket.SummarizedResolution}}
            </mat-expansion-panel>
          </mat-accordion>
        </mat-expansion-panel>
      </mat-accordion>
    </div>

    <ng-container *ngTemplateOutlet="feedbackTemplate; context: { cardType: AssistantCardType.OverallSummary }">
    </ng-container>
  </mat-card>
</ng-template>

<!--
    Template for ticket stats
-->
<ng-template #ticketStatsTemplate let-message="message">
  <mat-card appearance="elevated" class="message">
    <div style="margin-bottom: 1em;">
      <h3 class="assistant-flex-container">
        <b>Similar Ticket Statistics</b>
        <!-- Render the infoButtonTemplate -->
        <ng-container
          *ngTemplateOutlet="infoButtonTemplate; context: { cardType: AssistantCardType.SimilarTicketStatistics }">
        </ng-container>
      </h3>

      <!-- Using the template with custom headers and custom title -->
      <ng-container *ngTemplateOutlet="nameValueTableTemplate; context: {
          nameValueRows: message.TicketStats.StatisticsTable,
          title: ticketStatsTitle
        }">
      </ng-container>

      <div *ngIf="hasBlockStats">
        <!-- Using the template with custom headers and custom title -->
        <ng-container *ngTemplateOutlet="nameValueTableTemplate; context: {
              nameValueRows: message.TicketStats.BlockStatisticsTable,
              title: getBlockStatsTitle(message),
              statHeader: 'Block Reason',
              resultHeader: 'Times Used'
            }">
        </ng-container>
      </div>
    </div>
    <ng-container
      *ngTemplateOutlet="feedbackTemplate; context: { cardType: AssistantCardType.SimilarTicketStatistics }"></ng-container>
  </mat-card>
</ng-template>

<!-- nameValueTableTemplate -->
<ng-template #nameValueTableTemplate let-nameValueRows="nameValueRows" let-title="title  || 'Statistic Table'"
  let-nameHeader="nameHeader || 'Statistic'" let-valueHeader="valueHeader || 'Result'">
  <mat-accordion>
    <mat-expansion-panel>
      <mat-expansion-panel-header style="min-height: 48px;" [collapsedHeight]="'auto'" [expandedHeight]="'auto'">
        <mat-panel-title>
          {{ title }}
        </mat-panel-title>
        <mat-panel-description></mat-panel-description>
      </mat-expansion-panel-header>

      <table mat-table [dataSource]="nameValueRows" class="mat-elevation-z8">
        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>{{ nameHeader }}</th>
          <td mat-cell *matCellDef="let row">{{ row.Name }}</td>
        </ng-container>

        <!-- Value Column -->
        <ng-container matColumnDef="value">
          <th mat-header-cell *matHeaderCellDef>{{ valueHeader }}</th>
          <td mat-cell *matCellDef="let row">{{ row.Value }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="['name', 'value']"></tr>
        <tr mat-row *matRowDef="let row; columns: ['name', 'value'];"></tr>
      </table>
    </mat-expansion-panel>
  </mat-accordion>
</ng-template>

<!--
  Template for asset history
-->
<ng-template #assetHistoryTemplate let-message="message">
  <mat-card appearance="elevated" class="message">
    <div *ngIf="hasAssetHistory && hasAssetEvents; else noAssetHistoryMessage">
      <!-- Display the header outside of the markdown; note that we hide the first child in the markdown if it's an h2-->
      <h2 class="assistant-flex-container">
        <b>Asset History</b>
        <ng-container
          *ngTemplateOutlet="infoButtonTemplate; context: { cardType: AssistantCardType.AssetHistory }"></ng-container>
      </h2>
      <gdco-markdown class="hide-first-header" [markdown]="message.AssetHistory.InterventionSummary"></gdco-markdown>

      <div>
        <h4 id="summary-of-asset-events">Asset Events</h4>
        <mat-accordion>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>{{ getAssetEventsPanelTitle(message) }}</mat-panel-title>
              <mat-panel-description>
              </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="button-row">
              <button mat-button (click)="assetEventAccordion.openAll()">Expand All</button>
              <button mat-button (click)="assetEventAccordion.closeAll()">Collapse All</button>
            </div>
            <mat-accordion #assetEventAccordion="matAccordion" class="accordion-headers" multi>
              <mat-expansion-panel *ngFor="let assetEvent of message.AssetHistory.AssetEvents">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <span>Ticket</span>
                    <a style="margin-left: 0.5em;" target="_blank"
                      href="/tasks/details/{{assetEvent.TicketId}}">{{assetEvent.TicketId}}</a>
                  </mat-panel-title>
                  <mat-panel-description>
                    {{assetEvent.CreatedDate | date : "MM/dd/YYYY" }}
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <div *ngIf="assetEvent?.ProblemStatementSummary?.length > 0">
                  <h5>Problem Statement Summary</h5>
                  <div>
                    {{assetEvent.ProblemStatementSummary}}
                  </div>
                </div>
                <div *ngIf="assetEvent?.SolutionSummary?.length > 0">
                  <h5>Solution Summary</h5>
                  <div>
                    {{assetEvent.SolutionSummary}}
                  </div>
                </div>
                <div *ngIf="!!assetEvent?.TicketNotesSummary && assetEvent.TicketNotesSummary.length > 0">
                  <h5>Ticket Notes Summary</h5>
                  <div>
                    {{assetEvent.TicketNotesSummary}}
                  </div>
                </div>
                <div *ngIf="(assetEvent.HardwareReplacements?.length ?? 0) > 0">
                  <h5>Hardware Replacements</h5>
                  <div *ngFor="let hardwareReplacement of assetEvent.HardwareReplacements">
                    {{hardwareReplacement}}
                  </div>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>
    <ng-template #noAssetHistoryMessage>
      <p>There have been no previous tickets made against this AssetTag</p>
    </ng-template>

    <ng-container *ngTemplateOutlet="feedbackTemplate; context: { cardType: AssistantCardType.AssetHistory }">
    </ng-container>
  </mat-card>
</ng-template>

<!--
  Template for PhyNet Optical Contributor
 -->
<ng-template #phynetContributorTemplate>
  <mat-card appearance="elevated" class="message phynet-card" *ngIf="phyNetOpticalTableCommonData">
    <div>
      <h2 class="assistant-flex-container">
        <b>PhyNet Optical</b>
      </h2>

      Always follow the runbook instructions or instructions in the ticket.
      <ul class="phynet-process-guides">
        <li>
          <a href="https://aka.ms/DCO_AOC_TSG" target="_blank" rel="noopener noreferrer">Investigate Link AOC</a>
        </li>
        <li class="phynet-process-guides">
          <a href="https://aka.ms/DCO_Optical_TSG" target="_blank" rel="noopener noreferrer">Investigate Link Optical
            Span</a>
        </li>
        <li class="phynet-process-guides">
          <a href="https://aka.ms/DCO_NonOptical_TSG" target="_blank" rel="noopener noreferrer">Investigate Link Non
            Optical Span</a>
        </li>
      </ul>
    </div>

    <table mat-table [dataSource]="phyNetOpticalTableCommonData" class="mat-elevation-z8 gdco-table assistant-table">
      <ng-container matColumnDef="row_name">
        <td mat-cell *matCellDef="let element"><strong> {{element.row_name}} </strong></td>
      </ng-container>

      <ng-container matColumnDef="row_value">
        <td mat-cell *matCellDef="let element" [ngClass]="{'phyNetError': element.healthy === false}">
          {{element.row_value}} </td>
      </ng-container>

      <tr mat-row *matRowDef="let row; columns: phyNetOpticalTableCommonColumns;"></tr>
    </table>
    <p></p>
    <table mat-table [dataSource]="phyNetOpticalTableSidesData" class="mat-elevation-z8 gdco-table assistant-table">
      <ng-container matColumnDef="row_name">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element"><strong>{{element.row_name}} </strong></td>
      </ng-container>

      <ng-container matColumnDef="a_side">
        <th mat-header-cell *matHeaderCellDef><strong> A Side </strong></th>
        <td mat-cell *matCellDef="let element" [ngClass]="{'phyNetError': element.a_healthy === false}">
          {{element.a_side}} </td>
      </ng-container>

      <ng-container matColumnDef="z_side">
        <th mat-header-cell *matHeaderCellDef><strong> Z Side </strong></th>
        <td mat-cell *matCellDef="let element" [ngClass]="{'phyNetError': element.z_healthy === false}">
          {{element.z_side}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="phyNetOpticalTableSidesColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: phyNetOpticalTableSidesColumns;"></tr>
    </table>
    <p></p>

    <ng-container
      *ngTemplateOutlet="feedbackTemplate; context: { cardType: AssistantCardType.PhyNetOpticalContributor }">
    </ng-container>
  </mat-card>
</ng-template>

<!-- LearnCard template -->
<ng-template #learnCardTemplate let-learnCard="learnCard">
  <mat-card appearance="elevated" class="message">
    <div style="margin-bottom: 1em;">
      <h3 class="assistant-flex-container">
        <b>{{learnCard.Description}}</b>
        <!-- Render the infoButtonTemplate -->
        <ng-container
          *ngTemplateOutlet="infoButtonTemplate; context: { cardType: AssistantCardType.LearnCard }"></ng-container>
      </h3>
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header style="min-height: 48px;" [collapsedHeight]="'auto'" [expandedHeight]="'auto'">
            <mat-panel-title>{{ getLearnCardPanelTitle(learnCard) }}</mat-panel-title>
            <mat-panel-description>
            </mat-panel-description>
          </mat-expansion-panel-header>

          <table mat-table [dataSource]="learnCard.Videos" class="mat-elevation-z8 learn-card-table">
            <!-- Title Column (with hyperlink) -->
            <ng-container matColumnDef="title">
              <th mat-header-cell *matHeaderCellDef>Title</th>
              <td mat-cell *matCellDef="let video">
                <!-- Intercept the click before navigating so we can record clicks-->
                <a [href]="video.Url" target="_blank" (click)="handleLearningClick(video)">{{ video.Title }}</a>
              </td>
            </ng-container>

            <!-- Conditionally Render Description Column -->
            <ng-container *ngIf="videosHaveDescription" matColumnDef="description">
              <th mat-header-cell *matHeaderCellDef>Description</th>
              <td mat-cell *matCellDef="let video">{{ video.Description }}</td>
            </ng-container>

            <!-- Conditionally Render Duration Column -->
            <ng-container *ngIf="videosHaveDuration" matColumnDef="duration">
              <th mat-header-cell *matHeaderCellDef>Duration</th>
              <td mat-cell *matCellDef="let video">{{ video.Duration }}</td>
            </ng-container>

            <!-- Dynamically render header and row based on displayedColumns -->
            <tr mat-header-row *matHeaderRowDef="videosDisplayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: videosDisplayedColumns;"></tr>
          </table>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
    <ng-container
      *ngTemplateOutlet="feedbackTemplate; context: { cardType: AssistantCardType.LearnCard }"></ng-container>
  </mat-card>
</ng-template>
