<div [id]="id + '-query-container'" class="query-container">
  <p>{{ queryMessage }} 
    <a *ngIf="link" [href]="link" target="_blank" class="query-link">[Kusto Query]</a>
  </p>

  <ng-container *ngIf="error; else tableTemplate">
    <p>{{ error }}</p>
  </ng-container>

  <ng-template #tableTemplate>
    <app-table [result]="result"></app-table> <!-- Reusing the TableComponent -->
  </ng-template>
</div>
