<gdco-label *ngIf="_formFieldLabel || label" [for]="_matInput.id" [required]="required">
  <ng-container *ngIf="_formFieldLabel; else defaultLabelTemplate">
    <ng-container *ngTemplateOutlet="_formFieldLabel.template"></ng-container>
  </ng-container>
  <ng-template #defaultLabelTemplate>
    {{ label }}
  </ng-template>
</gdco-label>
<mat-form-field>
  <input
    #datepickerInput
    matInput
    [matDatepicker]="picker"
    [formControl]="formControl"
    [placeholder]="placeholder"
    [required]="required"
    [gdcoAutoFocus]="autoFocus"
  />
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker #picker></mat-datepicker>
  <mat-hint *ngIf="hint">{{ hint }}</mat-hint>
  <!-- <ng-container *ngFor="let errors of errorMessages | keyvalue">
    <mat-error *ngIf="formControl.hasError(errors.key)">{{ errors.value }}</mat-error>
  </ng-container> -->
</mat-form-field>
