<gdco-label *ngIf="_formFieldLabel || label" [for]="_matInput.id" [required]="required">
  <ng-container *ngIf="_formFieldLabel; else defaultLabelTemplate">
    <ng-container *ngTemplateOutlet="_formFieldLabel.template"></ng-container>
  </ng-container>
  <ng-template #defaultLabelTemplate>
    {{ label }}
  </ng-template>
</gdco-label>
<mat-form-field>
  <input
    matInput
    [matAutocomplete]="auto"
    [formControl]="formControl"
    [placeholder]="placeholder"
    [required]="required"
    [gdcoAutoFocus]="autoFocus"
    (keydown)="onInputKeydown($event)"
    [attr.aria-label]="label"
  />
  <mat-spinner *ngIf="loading" matSuffix [diameter]="20"></mat-spinner>
  <mat-autocomplete
    #auto="matAutocomplete"
    [displayWith]="displayFn"
    (optionSelected)="onOptionSelected($event)"
    autoActiveFirstOption="false"
  >
    <mat-option
      *ngFor="let option of $any(filteredOptions$ | async) | slice: 0:200"
      [value]="option"
      [attr.title]="displayFn(option)"
      (keydown)="onOptionKeydown($event)"
    >
      {{ displayFn(option) }}
    </mat-option>
  </mat-autocomplete>
  <mat-hint *ngIf="hint">{{ hint }}</mat-hint>
  <ng-container *ngFor="let errors of errorMessages | keyvalue">
    <mat-error *ngIf="formControl.hasError(errors.key)">{{ errors.value }}</mat-error>
  </ng-container>
</mat-form-field>
