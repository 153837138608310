import { Injectable } from '@angular/core';
import { ModelFactory, SimpleEntityStore } from '@gdco/store';
import { DpV2AssetSystem } from '../model';

@Injectable({ providedIn: 'root' })
export class DpAssetSystemsStoreV2 extends SimpleEntityStore<DpV2AssetSystem> {
  constructor(modelFactory: ModelFactory) {
    super(modelFactory);
  }

  public getEntityId(item: DpV2AssetSystem): string {
    return item.name;
  }
}
