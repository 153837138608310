<div *ngFor="let step of steps; let last = last; let index = index">
  <div fxLayout="row" fxLayoutGap="16px">
    <div fxLayout="column" fxLayoutAlign="start center">
      <mat-icon *ngIf="isStepCompleted(index); else notCompleted" class="step-indicator-icon" color="primary"
        >check_circle</mat-icon
      >
      <ng-template #notCompleted>
        <mat-icon
          *ngIf="!step.disabled && isStepActive(index); else notActive"
          class="step-indicator-icon"
          color="primary"
          >panorama_fish_eye</mat-icon
        >
      </ng-template>
      <ng-template #notActive>
        <mat-icon aria-disabled="true" class="step-indicator-icon" style="opacity: 0.2">lens</mat-icon>
      </ng-template>
      <mat-divider *ngIf="!last" [vertical]="true" fxFlex style="margin-top: -2px; margin-bottom: -2px"></mat-divider>
    </div>

    <div fxFlex>
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
        <span class="label-content" data-cy="stepper-label" *ngIf="!expandable; else clickableLabel">{{
          step.label
        }}</span>
        <div *ngIf="isStepCompleted(index) && !!step.summary && !!step.summaryActive" title="Edit step">
          <button class="edit-button" mat-icon-button (click)="onEditStepClick(step)" [disabled]="step.disabled">
            <mat-icon class="edit-button edit-button-icon">edit</mat-icon>
          </button>
        </div>
      </div>

      <div class="step-content">
        <ng-container *ngIf="showingStepAt(index)">
          <ng-container *ngIf="!isStepCompleted(index) || !step.summaryActive; else summary">
            <ng-container *ngTemplateOutlet="step.content"></ng-container>
          </ng-container>
          <ng-template #summary>
            <ng-container *ngTemplateOutlet="step.summary || step.content"></ng-container>
          </ng-template>
        </ng-container>
      </div>
    </div>
  </div>

  <ng-template #clickableLabel>
    <span class="label-content clickable-label" (click)="onLabelClicked(index)">{{ step.label || '[No Label]' }}</span>
  </ng-template>
</div>
