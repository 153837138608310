import { Component, Input, ElementRef, OnInit, OnDestroy, ViewChild } from '@angular/core';
import mermaid from 'mermaid';

mermaid.initialize({});

@Component({
  selector: 'assistant-chat-text',
  templateUrl: './assistant-chat-text.component.html'
})
export class AssistantChatTextComponent {
  @Input() content!: string;
}
