// contents of this file are automatically generated
export default {
  menuItems: [
    {
      applicationName: 'tasks-list',
      type: 'application'
    },
    {
      applicationName: 'tasks-query',
      type: 'application'
    },
    {
      applicationName: 'tasks-scheduler',
      type: 'application'
    },
    {
      applicationName: 'ce-inventory',
      type: 'application'
    },
    {
      type: 'group',
      children: [
        {
          applicationName: 'inventory-mgmt',
          type: 'application'
        },
        {
          applicationName: 'dbds-v2',
          type: 'application'
        },
        {
          applicationName: 'label-printing',
          type: 'application'
        },
        {
          applicationName: 'receive',
          type: 'application'
        },
        {
          applicationName: 'sku-lookup',
          type: 'application'
        }
      ]
    },
    {
      type: 'group',
      children: [
        {
          applicationName: 'tasks-dashboard',
          pageName: 'Home',
          type: 'application'
        },
        {
          applicationName: 'tasks-dashboard',
          pageName: 'TaskAssignment',
          type: 'application'
        }
      ]
    },
    {
      applicationName: 'requests',
      type: 'application'
    },
    {
      applicationName: 'fulfillments',
      type: 'application'
    },
    {
      applicationName: 'cablemanagement',
      type: 'application'
    },
    {
      applicationName: 'dock-confirmations',
      type: 'application'
    },
    {
      applicationName: 'labor',
      type: 'application'
    },
    {
      applicationName: 'labor-training',
      type: 'application'
    },
    {
      applicationName: 'reports',
      type: 'application'
    },
    {
      applicationName: 'cabling',
      type: 'application'
    },
    {
      applicationName: 'decommissions',
      type: 'application'
    },
    {
      applicationName: 'dcclosures',
      type: 'application'
    },
    {
      applicationName: 'digitized-procedure',
      type: 'application'
    },
    {
      applicationName: 'digitized-procedure-v2',
      type: 'application'
    },
    {
      applicationName: 'change-management',
      type: 'application'
    },
    {
      applicationName: 'cmms',
      type: 'application'
    },
    {
      applicationName: 'alert-button',
      type: 'application'
    },
    {
      applicationName: 'planned-maintenance',
      type: 'application'
    },
    {
      applicationName: 'policy-management',
      type: 'application'
    },
    {
      applicationName: 'runbooks',
      pageName: 'ITProcedureManagement',
      type: 'application'
    },
    {
      applicationName: 'action-portal',
      type: 'application'
    },
    {
      applicationName: 'cissg-metadata',
      type: 'application'
    },
    {
      applicationName: 'dbds-iotmanagement',
      type: 'application'
    },
    {
      applicationName: 'workspace',
      type: 'application'
    },
    {
      applicationName: 'tools-links',
      type: 'application'
    }
  ]
};
