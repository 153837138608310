<gdco-label *ngIf="_formFieldLabel || label" [for]="chipInputId" [required]="required">
  <ng-container *ngIf="_formFieldLabel; else defaultLabelTemplate">
    <ng-container *ngTemplateOutlet="_formFieldLabel.template"></ng-container>
  </ng-container>
  <ng-template #defaultLabelTemplate>
    <div id="chip-field-label-{{ label }}">
      {{ label }}
    </div>
  </ng-template>
</gdco-label>
<mat-form-field>
  <mat-chip-grid #chipList [required]="required" aria-label="ChipList" gdcoRemoveAria [disabled]="disabled">
    <mat-chip-row
      *ngFor="let item of formControl.value"
      [removable]="removable"
      [disabled]="disabled"
      (removed)="remove(item)"
      attr.aria-label="{{ label }}. {{ shortDisplayFn(item) }}"
    >
      {{ shortDisplayFn(item) }}
      <mat-icon *ngIf="removable" matChipRemove>cancel</mat-icon>
    </mat-chip-row>
  </mat-chip-grid>
  <input
    #chipInput
    [formControl]="inputControl"
    [placeholder]="placeholder"
    [matAutocomplete]="auto"
    [matChipInputFor]="chipList"
    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
    [gdcoAutoFocus]="autoFocus"
    attr.aria-labelledby="chip-field-label-{{ label }}"
    [attr.aria-describedby]="errorId"
    (blur)="onBlur()"
  />
  <mat-spinner *ngIf="loading" matSuffix [diameter]="20"></mat-spinner>
  <mat-autocomplete
    autoActiveFirstOption="false"
    #auto="matAutocomplete"
    [displayWith]="displayFn"
    (optionSelected)="onOptionSelected($event)"
  >
    <mat-option
      *ngFor="let option of $any(filteredOptions$ | async) | slice: 0 : 200"
      [value]="option"
      [attr.title]="displayFn(option)"
    >
      {{ displayFn(option) }}
    </mat-option>
  </mat-autocomplete>
  <mat-hint *ngIf="hint">{{ hint }}</mat-hint>
  <mat-error *ngIf="errorMessages" [id]="errorId">{{ getErrorMessages() }}</mat-error>
</mat-form-field>
