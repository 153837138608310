import { ChangeDetectorRef, Component, Input, Type } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AssistantMessage } from '../models';
import { AssistantChatFeedbackThumbup, AssistantChatFeedbackThumbdown } from '.';

@Component({
  selector: 'assistant-message-footer',
  templateUrl: './assistant-message-footer.component.html'
})
export class AssistantMessageFooterComponent {
  @Input() message!: AssistantMessage;

  constructor(
    private changeDetector: ChangeDetectorRef,
    private dialog: MatDialog,
  ) { }

  /**
   * Handle the click event for the thumb up button
   */
  onThumbup = (): void => {
    const dialogRef = this.showDialog(AssistantChatFeedbackThumbup);

    // Handle the result of the dialog
    dialogRef
      .afterClosed()
      .subscribe(() => {
        this.changeDetector.detectChanges();
      });
  };

  /**
   * Handle the click event for the thumb down button
   */
  onThumbdown = (): void => {
    const dialogRef = this.showDialog(AssistantChatFeedbackThumbdown);

    // Handle the result of the dialog
    dialogRef
      .afterClosed()
      .subscribe(() => {
        this.changeDetector.detectChanges();
      });
  };

  /**
 * Use the assistant chooser dialog to select a new assistant
 * @returns MatDialogRef<TType>
 */
  private showDialog = <TType>(component: Type<TType>): MatDialogRef<TType> => {
    return this.dialog.open(component, {
      width: 'auto',
      minWidth: '400px',
      maxWidth: '400px',
      autoFocus: false,
      data: { message: this.message }
    });
  };
}
