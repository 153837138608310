<ng-container *ngIf="showSearch">
  <input
    cdk-overlay-origin
    #panelOrigin="cdkOverlayOrigin"
    [attr.aria-label]="currentSearchProvider.placeholderText"
    [placeholder]="currentSearchProvider.placeholderText"
    #quickSearchInput
    type="text"
    [(ngModel)]="searchText"
    (ngModelChange)="onSearchTextChange()"
    (keydown)="onKeydown($event)"
    (focus)="setModeAndGetTextForFiltering()"
    (click)="setModeAndGetTextForFiltering()"
    class="search-input-v2"
    aria-owns="suggestion-dropdown"
  />
  <div class="hidden-element" id="aria-element" aria-live="assertive"></div>
  <button
    aria-label="Search by"
    *ngIf="validSearchProviders && validSearchProviders.length > 1"
    mat-icon-button
    [matMenuTriggerFor]="searchProviderMenu"
  >
    <mat-icon>keyboard_arrow_down</mat-icon>
  </button>
  <mat-menu #searchProviderMenu>
    <ng-container *gdcoPrivateCloud>
      <button
        [attr.aria-label]="searchProvider.name"
        mat-menu-item
        *ngFor="let searchProvider of validSearchProviders"
        (click)="setSearchProvider(searchProvider)"
      >
        {{ searchProvider.name }}
      </button>
    </ng-container>
    <ng-container *gdcoPrivateCloud="'Show'">
      <ng-container *ngFor="let searchProvider of validSearchProviders">
        <button
          [attr.aria-label]="searchProvider.name"
          mat-menu-item
          *ngIf="searchProvider.enabledToPrivateCloud"
          (click)="setSearchProvider(searchProvider)"
        >
          {{ searchProvider.name }}
        </button>
      </ng-container>
    </ng-container>
  </mat-menu>
</ng-container>
<button class="search-button" aria-label="Search" mat-icon-button (click)="onSearchV2ButtonClicked()">
  <mat-icon class="search-icon-v2">search</mat-icon>
</button>
<button
  *ngIf="showSearch"
  class="close-search-button"
  aria-label="Close search box"
  mat-icon-button
  (click)="onCloseSearchBoxClicked()"
>
  <mat-icon>close</mat-icon>
</button>

<ng-template cdk-portal #searchModePanel="cdkPortal">
  <div id="suggestion-dropdown" class="suggestion-panel mat-menu-panel">
    <div
      class="suggestion-panel-options suggestions"
      #suggestions
      role="listbox"
      aria-label="Search modes"
      tabindex="-1"
      (keydown)="onKeydown($event)"
    >
      <ng-container *gdcoPrivateCloud>
        <mat-option *ngFor="let searchProvider of validSearchProviders" [value]="searchProvider">
          {{ searchProvider.name }}
        </mat-option>
      </ng-container>
      <ng-container *gdcoPrivateCloud="'Show'">
        <ng-container *ngFor="let searchProvider of validSearchProviders">
          <mat-option *ngIf="searchProvider.enabledToPrivateCloud" [value]="searchProvider">
            {{ searchProvider.name }}
          </mat-option>
        </ng-container>
      </ng-container>
    </div>
  </div>
</ng-template>

<ng-template cdk-portal #suggestionPanel="cdkPortal">
  <div gdcoElevation="8">
    <div
      id="suggestion-dropdown"
      class="suggestion-panel mat-menu-panel"
      *ngIf="suggestionMode === SuggestionMode.StaticDropDownSuggestion"
    >
      <div class="mat-optgroup-label"><b>Filter by field</b> {{ currentSearchProvider.basicSampleText }}</div>
      <div
        class="suggestion-panel-options suggestions"
        #suggestions
        role="listbox"
        aria-label="Suggestions"
        tabindex="-1"
        (keydown)="onKeydown($event)"
      >
        <mat-option *ngFor="let suggestion of suggestedValues" [value]="suggestion.value">
          {{ suggestion.displayValue }}
        </mat-option>
      </div>
      <div class="mat-optgroup-label">Start typing to find out more...</div>
      <div class="mat-optgroup-label"><b>Operators</b> {{ currentSearchProvider.advancedSampleText }}</div>
      <div
        class="suggestion-panel-options"
        role="listbox"
        aria-label="Operators"
        tabindex="-1"
        (keydown)="onKeydown($event)"
      >
        <mat-option value="AND"> AND </mat-option>
        <mat-option value="NOT"> NOT </mat-option>
        <mat-option value="OR"> OR </mat-option>
      </div>
    </div>

    <div class="suggestion-panel mat-menu-panel" *ngIf="suggestionMode === SuggestionMode.FieldSuggestion">
      <div class="mat-optgroup-label"><b>Filter by field</b> {{ currentSearchProvider.basicSampleText }}</div>
      <div
        class="suggestion-panel-options suggestions"
        #suggestions
        role="listbox"
        aria-label="Suggestions"
        tabindex="-1"
        (keydown)="onKeydown($event)"
      >
        <mat-option *ngFor="let suggestion of suggestedValues" [value]="suggestion.value">
          {{ suggestion.displayValue }}
        </mat-option>
      </div>
      <div><b>Operators</b> {{ currentSearchProvider.advancedSampleText }}</div>
      <div
        class="suggestion-panel-options"
        role="listbox"
        aria-label="Operators"
        tabindex="-1"
        (keydown)="onKeydown($event)"
      >
        <mat-option value="AND "> AND </mat-option>
        <mat-option value="NOT "> NOT </mat-option>
        <mat-option value="OR "> OR </mat-option>
      </div>
    </div>

    <div class="suggestion-panel mat-menu-panel" *ngIf="suggestionMode === SuggestionMode.ValueSuggestion">
      <div
        class="suggestion-panel-options suggestions"
        #suggestions
        role="listbox"
        tabindex="-1"
        aria-label="Suggestions"
        (keydown)="onKeydown($event)"
      >
        <gdco-virtualize fxFill [items]="suggestedValues" (scroll)="onScroll($event)">
          <mat-option
            *ngFor="let suggestion of suggestedValues | slice: scrollWindow.start:scrollWindow.end"
            [value]="suggestion.value"
          >
            {{ suggestion.displayValue }}
          </mat-option>
        </gdco-virtualize>
      </div>
    </div>
  </div>
</ng-template>
