<h4 *ngIf="data?.title" class="dialog-label" id="confirmation-dialog">
  {{ data.title }}
</h4>
<div
  *ngIf="data?.text"
  class="dialog-content"
  [style.height]="contentHeight"
  [style.max-height]="maxContentHeight"
  [style.white-space]="whiteSpace"
>
  {{ data.text }}
</div>
<gdco-action-bar align="end">
  <button *ngIf="!data?.hideRejectButton" mat-stroked-button color="primary" gdcoAutoFocus [mat-dialog-close]="false">
    {{ rejectButtonText }}
  </button>
  <button mat-flat-button color="primary" [mat-dialog-close]="true">{{ acceptButtonText }}</button>
</gdco-action-bar>
