<gdco-notification-cards-container
  [count]="notificationsCount$ | async"
  [clearable]="anyClearableNotifications$ | async"
  (clearAll)="onClearAll()"
  (navigate)="onNavigate()"
  (closeCard)="onClose()"
>
  <div *ngIf="anyNotifications$ | async" class="cards-content">
    <ng-container *ngFor="let notification of notifications$ | async; let last = last">
      <gdco-notification-card
        [notificationRef]="notification"
        (dismiss)="onDismissNotification($event)"
        (acknowledge)="onAcknowledgeNotification($event)"
        (navigate)="onNavigate()"
        (viewDetails)="onViewDetails($event)"
      ></gdco-notification-card>
      <mat-divider *ngIf="!last"></mat-divider>
    </ng-container>
  </div>
</gdco-notification-cards-container>
