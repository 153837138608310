<div class="danaui-chat-message-footer">
  <div class="left-side">
  </div>

  <div class="right-side">
    <span class="footer-label">Was the response helpful?</span>
    <button mat-icon-button aria-label="feedback" matTooltip="Thumb Up" (click)="onThumbup()">
      <mat-icon>thumb_up</mat-icon>
    </button>
    <button mat-icon-button aria-label="feedback" matTooltip="Thumb Down" (click)="onThumbdown()">
      <mat-icon>thumb_down</mat-icon>
    </button>
  </div>
</div>