import { AssistantBaseMessage } from './assistant-base-message';
import { MessageFormat, MessageSegment } from './message-segment';

/**
 * Model for Assistant Message that extends AssistantBaseMessage and handles message formating and parsing of markdown/mermaid
 * as well as splitting the text into segments based on markdown/mermaid indicators
 */
export class AssistantMessage extends AssistantBaseMessage {
    text: string;
    messageFormat: MessageFormat;
    kustoLink?: string;
    rowCount?: number;
    colCount?: number;
    rowExceedMax?: boolean;
    colExceedMax?: boolean;
    result: object[];
    error?: string;
    segments: MessageSegment[] = [];

    constructor(partial?: Partial<AssistantMessage>) {
        super(partial || {});
        this.text = partial?.text || '';
        this.messageFormat = partial?.messageFormat || 'markdown';
        this.kustoLink = partial?.kustoLink || undefined;
        this.rowCount = partial?.rowCount || 0;
        this.colCount = partial?.colCount || 0;

        this.rowExceedMax = (partial && 'rowExceedMax' in partial) ? partial.rowExceedMax : undefined;
        this.colExceedMax = (partial && 'colExceedMax' in partial) ? partial.colExceedMax : undefined;
        this.result = partial?.result || [];
        this.error = partial?.error || undefined;
        this.segments = [];

        if (partial?.messageFormat === 'mermaid') {
            this.segments.push(new MessageSegment(this.text, 'mermaid'));
        } else if (partial?.messageFormat === 'query') {
            this.segments.push(new MessageSegment(this.text, 'query'));
        } else {
            this.createSegments();
        }
    }

    /**
     * Concatenate all segments into a single string
     * @param delimeter - Delimeter to use to concatenate segments
     * @returns Concatenated string of all segments
     */
    concatSegments(delimeter: string = '\n\n'): string {
        const allText = this.segments.map(segment => segment.text).join(delimeter);
        return allText;
    }

    /**
     * Split the text into segments based on markdown/mermaid indicators
     */
    private createSegments(): void {
        // Split text into segments based on markdown/mermaid indicators
        const lines = this.text.split('\n');
        let currentType: MessageFormat = 'markdown';
        let currentText = '';
        let insideMermaidBlock = false;

        const pushSegment = () => {
            if (currentText.trim() !== '') {
                this.segments.push(new MessageSegment(currentText, currentType));
                // Reset currentText
                currentText = '';
                currentType = 'markdown';
            }
        };

        // Loop through each line and separate text into segments based on whether the text is 'markdown' or 'mermaid'
        for (const line of lines) {
            // inside the mermaid block
            if (insideMermaidBlock) {
                // end of mermaid block
                if (this.detectTermination('mermaid', line)) {
                    pushSegment();
                    insideMermaidBlock = false;
                    // add to currentText
                } else {
                    currentText += line + '\n';
                }
            } else {
                // start of mermaid block
                if (this.detectFormat(line) === 'mermaid') {
                    pushSegment();
                    insideMermaidBlock = true;
                    currentType = 'mermaid';
                    // regular markdown
                } else {
                    currentText += line + '\n';
                }
            }
        }

        // Push the last segment into segments array if it's not empty
        pushSegment();
    }

    /**
     * Detect the format of the line based on the indicators
     * @param line 
     * @returns 
     */
    private detectFormat(line: string): MessageFormat {
        // Check if the line specifically starts with '```mermaid'
        if (line.trim().startsWith('```mermaid')) {
            return 'mermaid';
        }

        // Return 'markdown' format if no Mermaid indicators are found
        return 'markdown';
    }

    /**
     * Detect the termination of the format based on the indicators
     * @param format 
     * @param line 
     * @returns 
     */
    private detectTermination(format: MessageFormat, line: string): boolean {
        if (format === 'mermaid') {
            return line.trim().startsWith('```');
        }

        const detectedFormat: MessageFormat = this.detectFormat(line);
        return detectedFormat !== 'markdown';
    }
}