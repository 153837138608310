import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatSelectionList } from '@angular/material/list';
import { MatDialogRef } from '@angular/material/dialog';
import { AssistantChatService } from '../services';
import { Assistant } from '../models';

@Component({
  selector: 'assistant-chat-assistant-chooser',
  templateUrl: './assistant-chat-assistant-chooser.component.html',
  styles: [`.dialog-actions-right { display: flex;  justify-content: flex-end; gap: 0.5em; }`],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AssistantChatAssistantChooser implements OnInit {
  @Output() assistantSelected = new EventEmitter<Assistant>();
  @Output() errorOccurred = new EventEmitter<string>();

  @ViewChild('assistantSelectList', { static: false }) selectList!: MatSelectionList;
  assistants: Assistant[] | null = null;

  constructor(public dialogRef: MatDialogRef<AssistantChatAssistantChooser>,
    private changeDetector: ChangeDetectorRef,
    private assistantChatService: AssistantChatService) {
  }

  /**
   * Handle loading available assistants
   */
  async ngOnInit(): Promise<void> {
    try {
      this.assistants = await this.assistantChatService.getAssistants(true);
      this.changeDetector.markForCheck();
    } catch (error) {
      this.errorOccurred.emit('Error loading assistants');
      this.dialogRef.close({ error: 'Error loading assistants' });
    }
  }

  cancel(): void {
    this.dialogRef.close();
  }

  ok(): void {
    const assistant = this.selectList.selectedOptions.selected[0]?.value;
    if (assistant) {
      this.dialogRef.close({ assistant: assistant });
    } else {
      this.dialogRef.close();
    }
  }
}