<gdco-label *ngIf="_formFieldLabel || label" [for]="_matInput.id" [required]="required">
  <ng-container *ngIf="_formFieldLabel; else defaultLabelTemplate">
    <ng-container *ngTemplateOutlet="_formFieldLabel.template"></ng-container>
  </ng-container>
  <ng-template #defaultLabelTemplate>
    {{ label }}
  </ng-template>
</gdco-label>
<mat-form-field>
  <input
    matInput
    [formControl]="formControl"
    [placeholder]="placeholder"
    [required]="required"
    [maxlength]="maxlength"
    [gdcoAutoFocus]="autoFocus"
  />
  <mat-hint *ngIf="hint">{{ hint }}</mat-hint>
  <ng-container *ngFor="let errors of errorMessages | keyvalue">
    <mat-error *ngIf="formControl.hasError(errors.key)">{{ errors.value }}</mat-error>
  </ng-container>
  <mat-error *ngIf="required && !_matInput.value">Required</mat-error>
</mat-form-field>
