<div fxLayout="row" class="notification-bar-container">
  <mat-icon [style.color]="color">{{ icon }}</mat-icon>
  <span class="gdco-notification-title">{{ title }}</span>
  <span class="gdco-notification-message">{{ notification?.message }}</span>
  <div fxFlex></div>
  <button
    gdcoTrack="NotificationBarDetailClick"
    class="gdco-notification-details"
    mat-button
    [gdcoAutoFocus]="autoFocusDetailsButton"
    [style.color]="color"
    (click)="onDetailsButtonClick()"
  >
    Details
  </button>
</div>
