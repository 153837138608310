import { HandlerType } from "./handler-type";
import { handlerTypes } from "./handler-types";

export class AssistantTool {
    tool_api_spec: ToolApiSpec;
    function_handler: FunctionHandler;

    get handlerType(): HandlerType | undefined {
        return handlerTypes.find(x => x.value === this.function_handler.handler_type);
    }

    constructor(data?: Partial<AssistantTool>) {
        this.tool_api_spec = new ToolApiSpec(data?.tool_api_spec);
        this.function_handler = new FunctionHandler(data?.function_handler);
    }
}

export class ToolApiSpec {
    name: string = '';
    description: string = '';
    parameters: ToolApiSpecParameters;

    constructor(data?: Partial<ToolApiSpec>) {
        this.name = data?.name ?? this.name;
        this.description = data?.description ?? this.description;
        this.parameters = new ToolApiSpecParameters(data?.parameters);
    }
}

export class ToolApiParameter {
    type: string;
    name: string;
    description: string;

    constructor(data?: Partial<ToolApiParameter>) {
        this.type = data?.type ?? 'string';
        this.name = data?.name ?? ''
        this.description = data?.description ?? '';
    }
}

export class ToolApiSpecParameters {
    type: string = 'object';
    properties: {
        [key: string]: ToolApiParameter;
    };
    required: string[];

    constructor(data?: Partial<ToolApiSpecParameters>) {
        this.type = data?.type ?? 'object';
        this.properties = data?.properties ?? {};
        this.required = data?.required ?? [];
    }
}

export class FunctionHandler {
    handler_type: string;
    authentication_strategy: string;
    web_url: string;
    web_method: string;
    show_invocation: boolean;
    auto_response: string;

    constructor(data?: Partial<FunctionHandler>) {
        this.handler_type = data?.handler_type ?? 'web_endpoint';
        this.authentication_strategy = data?.authentication_strategy ?? '';
        this.web_url = data?.web_url ?? '';
        this.web_method = data?.web_method ?? '';
        this.show_invocation = data?.show_invocation ?? false;
        this.auto_response = data?.auto_response ?? '';
    }
}