<div *ngIf="showInstallButton" gdcoFlight="ServiceWorker">
  <div *ngIf="isMobile$ | async" class="install-button-wrapper">
    <button mat-stroked-button color="primary" (click)="onInstallClicked()" class="install-button">
      <mat-icon>get_app</mat-icon>
      <span>Install GDCO App</span>
    </button>
  </div>
</div>
<mat-nav-list class="menu-list">
  <a class="menu-item" mat-list-item [routerLink]="'/'" (click)="onMenuItemClicked()">
    <div class="menu-item-contents">
    <mat-icon>home</mat-icon>
    <span class="menu-display-name">Home</span>
    </div>
  </a>
  <mat-divider></mat-divider>
  <ng-container *ngFor="let menuItem of menuItems">
    <a
      class="menu-item"
      *gdcoAuth="null; settings: menuItem.authorization"
      [attr.data-test]="'main-menu-item:' + menuItem.name"
      mat-list-item
      [gdcoFlight]="menuItem.flighting"
      [routerLink]="'/' + menuItem.url"
      (click)="onMenuItemClicked()"
    >
    <div class="menu-item-contents">
      <mat-icon>{{ menuItem.icon }}</mat-icon>
      <span class="menu-display-name">{{ menuItem.displayName }}</span>
    </div>
    </a>
    <ng-container *ngIf="!menuItem.group">
      <mat-divider *gdcoAuth="null; settings: menuItem.authorization" [gdcoFlight]="menuItem.flighting"></mat-divider>
    </ng-container>
  </ng-container>
</mat-nav-list>
<div class="stretch"></div>
<mat-nav-list *ngIf="!production" class="menu-list menu-docs">
  <mat-divider></mat-divider>
  <a mat-list-item href="https://aka.ms/gdcowiki" target="_blank" rel="noreferrer">
    <div class="menu-item-contents">
    <mat-icon>description</mat-icon>
    <span class="menu-display-name">GDCO Platform Documentation</span>
  </div>
  </a>
</mat-nav-list>
