import { Component, Input } from '@angular/core';
import { AssistantChatMessage, AssistantMessage, AssistantWaitingMessage } from '../models';

@Component({
  selector: 'assistant-message',
  templateUrl: './assistant-message.component.html'
})
export class AssistantMessageComponent {
  @Input() message!: AssistantChatMessage;

  isWaitingMessage(): boolean {
    return (this.message as AssistantWaitingMessage)?.type === 'waiting';
  }

  isUserMessage(): boolean {
    if (this.message instanceof AssistantMessage) {
      return this.message?.isUser;
    }

    return false;
  }
}
