<ng-container *ngIf="assistant; else loading">
  <div class="button-container margin-bottom">
    <button class="mat-button-svg" (click)="handleNewChat()" mat-stroked-button color="primary">
      <svg fill="currentColor" width="20" height="20" viewBox="0 0 20 20">
        <path
          d="M6.75 3.5a2.25 2.25 0 1 0 0 4.5 2.25 2.25 0 0 0 0-4.5ZM3.5 5.75a3.25 3.25 0 1 1 6.5 0 3.25 3.25 0 0 1-6.5 0Zm0 4.25a2 2 0 0 0-2 2V12.08a1.72 1.72 0 0 0 .01.18 3.95 3.95 0 0 0 .67 1.8C2.88 15.09 4.24 16 6.75 16c.95 0 1.73-.13 2.37-.35A5.51 5.51 0 0 1 9 14.63c-.56.22-1.3.37-2.25.37-2.24 0-3.26-.8-3.74-1.5a2.95 2.95 0 0 1-.5-1.42l-.01-.02V12a1 1 0 0 1 1-1H10a1 1 0 0 1 .23.03c.23-.28.49-.54.76-.77A2 2 0 0 0 10 10H3.5Zm11-5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3ZM12 6.5a2.5 2.5 0 1 1 5 0 2.5 2.5 0 0 1-5 0ZM14.5 19a4.5 4.5 0 1 0-3.94-2.32l-.54 1.8c-.1.3.2.6.5.5l1.8-.54c.64.36 1.39.56 2.18.56ZM12 13.5c0-.28.22-.5.5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5Zm.5 2.5a.5.5 0 0 1 0-1h2a.5.5 0 0 1 0 1h-2Z" />
      </svg>
      New Chat
    </button>

    <button class="mat-button-svg" mat-stroked-button color="primary" (click)="handleChooseAssistant()">
      <svg fill="currentColor" width="20" height="22" viewBox="0 0 20 22">
        <path
          d="M17.75 14C19 14 20 15 20 16.25v.9c0 1.1-.47 2.14-1.3 2.85-1.57 1.34-3.81 2-6.7 2s-5.13-.66-6.7-2A3.75 3.75 0 0 1 4 17.16v-.91C4 15 5.01 14 6.25 14h11.5Zm0 1.5H6.25a.75.75 0 0 0-.75.75v.9c0 .66.29 1.29.79 1.71C7.55 19.94 9.44 20.5 12 20.5s4.46-.56 5.72-1.64c.5-.43.78-1.05.78-1.7v-.91a.75.75 0 0 0-.75-.75ZM11.9 2h.1c.38 0 .7.28.74.65l.01.1v.75h3.5c1.24 0 2.25 1 2.25 2.25v4.5c0 1.25-1 2.25-2.25 2.25h-8.5c-1.24 0-2.25-1-2.25-2.25v-4.5c0-1.24 1-2.25 2.25-2.25h3.5v-.75c0-.38.28-.7.65-.74L12 2h-.1Zm4.35 3h-8.5a.75.75 0 0 0-.75.75v4.5c0 .42.34.75.75.75h8.5c.41 0 .75-.33.75-.75v-4.5a.75.75 0 0 0-.75-.75Zm-6.5 1.5a1.25 1.25 0 1 1 0 2.5 1.25 1.25 0 0 1 0-2.5Zm4.5 0a1.25 1.25 0 1 1 0 2.5 1.25 1.25 0 0 1 0-2.5Z">
        </path>
      </svg>
      Choose Assistant
    </button>
  </div>

  <assistant-message-list class="danaui-chat-container" [assistant]="assistant"
    [messages]="messages"></assistant-message-list>
  <assistant-message-input class="danaui-chat-input-container" (send)="sendMessage($event)"></assistant-message-input>
</ng-container>

<!-- Display a loading indicator or error -->
<ng-template #loading>
  <ng-container #loading *ngIf="error; else loadingSpinner">
    <ng-container *ngTemplateOutlet="errorTemplate"></ng-container>
  </ng-container>
</ng-template>

<!-- Error message block -->
<ng-template #errorTemplate>
  <div class="error-message">
    <p>{{ error }}</p>
    <button mat-stroked-button color="primary" (click)="retry()">Retry</button>
  </div>
</ng-template>

<ng-template #loadingSpinner>
  <div class="loading-prompt">
    <mat-spinner [diameter]="40" class="loader"></mat-spinner>
    <p>Loading...</p>
  </div>
</ng-template>

<gdco-dialog #chooseAssistantDialog label="Choose an Assitant">
  <ng-template gdcoDialogContent>
    <assistant-chat-assistant-chooser></assistant-chat-assistant-chooser>
  </ng-template>
</gdco-dialog>