<!-- <div class="dana-chat-message-container"> -->
<div class="dana-chat-avatar">
  <div class="dana-chat-avatar-container">
    <bot-icon></bot-icon>
  </div>
</div>
<div class="dana-chat-message">
  <!-- Header which has copy capability and displays timestamp-->
  <ng-container *ngIf="message.displayHeader">
    <assistant-message-header [message]="message"></assistant-message-header>
  </ng-container>
  <ng-container *ngFor="let segment of message.segments; let index = index">
    <ng-container [ngSwitch]="segment.type">
      <!-- Mermaid Component -->
      <assistant-chat-mermaid *ngSwitchCase="'mermaid'" id="mermaid-{{index}}" [source]="segment.text">
      </assistant-chat-mermaid>

      <!-- Query Component -->
      <assistant-chat-query *ngSwitchCase="'query'" [source]="segment.text" [link]="message.kustoLink"
        [rowCount]="message.rowCount" [colCount]="message.colCount" [rowExceedMax]="message.rowExceedMax"
        [colExceedMax]="message.colExceedMax" [result]="message.result" [error]="message.error">
      </assistant-chat-query>

      <!-- Markdown Component (default case) -->
      <assistant-chat-markdown *ngSwitchDefault [content]="segment.text">
      </assistant-chat-markdown>
    </ng-container>
  </ng-container>
  <!-- Footer which has feedback buttons. If we're displaying header, we're also show footer -->
  <ng-container *ngIf="message.displayHeader">
    <assistant-message-footer [message]="message"></assistant-message-footer>
  </ng-container>
  <div class="dana-chat-message-arrow"></div>
</div>
<!-- </div> -->