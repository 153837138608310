<!--
    The headerTemplate is always displayed on the assistant panel. It contains the logo, the title, the feedback button, and the close button.
    We have two templates for assistant panel's content. One is displayed when we're loading and if we're not loading$ the other is displayed.
    The loadingTemplate is displayed when we're loading. It contains a spinner and a message.
    The conversationTemplate is displayed when we're not loading. It contains the messages that the assistant has sent.
-->
<div fxFill class="assistant-frame">
  <ng-container *ngTemplateOutlet="headerTemplate"></ng-container>

  <ng-container [ngSwitch]="viewState">
    <!-- Case for both Assistant and Chat -->
    <ng-container *ngSwitchCase="viewStateEnum.InsightsAndChat">
      <mat-tab-group mat-stretch-tabs>
        <mat-tab label="Assistant">
          <ng-container class="tab-content"
            *ngTemplateOutlet="(loading$ | async) ? loadingTemplate : conversationTemplate">
          </ng-container>
        </mat-tab>
        <mat-tab label="Assistant Chat">
          <!-- Lazy load the chat component -->
          <ng-template matTabContent>
            <assistant-chat class="tab-content"></assistant-chat>
          </ng-template>
        </mat-tab>
      </mat-tab-group>
    </ng-container>

    <!-- Case for Chat only -->
    <ng-container *ngSwitchCase="viewStateEnum.ChatOnly">
      <assistant-chat class="tab-content"></assistant-chat>
    </ng-container>

    <!-- Case for Assistant only or undefined state -->
    <ng-container *ngSwitchDefault>
      <ng-container *ngTemplateOutlet="(loading$ | async) ? loadingTemplate : conversationTemplate"></ng-container>
    </ng-container>
  </ng-container>

  <div id="inAppFeedbackRef-id"></div> <!--placeholder for feedback sdk js code to attach and execute-->
</div>

<ng-template #headerTemplate>
  <!-- header -->
  <div class="header">
    <h3 class="title">Assistant</h3>
    <div fxFlex></div>
    <span class="auto-pop-prefix">Auto-pop: </span>
    <mat-slide-toggle aria-label="Auto-pop" (change)="onAutoPopChange($event)" [checked]="isAutoPopEnabled$ | async"
      class="auto-pop-toggle"></mat-slide-toggle>
    <span class="auto-pop-postfix"> {{(isAutoPopEnabled$ | async) ? "On": "Off"}}</span>
    <!-- Comment the feedback button altogether as initial results and UX guidance is to rely on the Thumbs Up / Down on a card basis for specific feedback.  -->
    <!-- <button mat-button class="feedback-button" (click)="openDialog()">Feedback</button> -->
    <button mat-icon-button aria-label="Close assistant" (click)="onCloseClick()"><mat-icon>close</mat-icon></button>
  </div>
</ng-template>

<ng-template #loadingTemplate>
  <!-- loading -->
  <div class="loading-prompt">
    <mat-spinner [diameter]="40" class="loader"></mat-spinner>
    <p>Calculating...</p>
    <p>This may take up to 1 minute</p>
  </div>
</ng-template>

<ng-template #conversationTemplate>
  <!-- conversation -->
  <div class="conversation" fxLayout="column" fxFlex fxLayoutGap="8px" #messageContainer
    (mouseenter)="onAssistantMouseOver()">
    <ng-container *ngFor="let message of messages$ | async; let i = index" [ngSwitch]="message.ElementType">
      <tasks-assistant-user-prompt-message *ngSwitchCase="'chat'" [message]="message">
      </tasks-assistant-user-prompt-message>
      <tasks-assistant-recommendation-message *ngIf="i !== 0 && message.ElementType === 'recommendation'"
        [message]="message.Recommendation" [sessionId]="sessionId" [sequenceId]="message.SequenceId">
      </tasks-assistant-recommendation-message>
    </ng-container>
  </div>
</ng-template>