<mat-toolbar class="progress-toolbar" [color]="color">
  <ng-content></ng-content>

  <mat-progress-bar
    *ngIf="loading"
    class="progress-bar"
    [attr.aria-label]="ariaLabel"
    [ngClass]="{ top: progressAlign !== 'bottom', bottom: progressAlign === 'bottom' }"
    [color]="progressColor"
    [mode]="progressMode"
    [bufferValue]="progressBufferValue"
    [value]="progressValue"
  >
  </mat-progress-bar>
</mat-toolbar>
