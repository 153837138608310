<div fxFill fxLayout="column">
  <ng-container *ngTemplateOutlet="header?.directive?.template || defaultHeader"></ng-container>
  <ng-template #defaultHeader>
    <mat-toolbar gdcoBorder="bottom" class="page-toolbar" role="banner">
      <ng-container *ngIf="pageTitle; else pageTitleContent">
        <h2>{{ pageTitle }}</h2>
      </ng-container>
      <ng-template #pageTitleContent>
        <ng-content select="gdco-page-title"></ng-content>
      </ng-template>

      <div class="stretch" fxFlex></div>

      <ng-content select="gdco-page-actions"></ng-content>
    </mat-toolbar>
  </ng-template>

  <div fxFlex="0px" class="gdco-page-content-wrapper">
    <ng-content select="gdco-page-content"></ng-content>
  </div>
</div>
