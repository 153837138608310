<div class="danaui-chat-input-form">
  <mat-form-field [formGroup]="chatForm" style="width:100%;">
    <textarea matInput [formControl]="inputControl" (keydown)="handleKeyDown($event)"
      placeholder="Write your message here"></textarea>
  </mat-form-field>
</div>

<div class="danaui-chat-input-footer">
  <div class="left-side">
    <input type="file" accept=".txt,.pdf,.docx,.md,.jpg,.jpeg,.png,.tif,.tiff,.bmp,.gif" multiple hidden #fileInput>
    <button mat-icon-button [disabled]="true" aria-label="Attach file button" (click)="fileInput.click()">
      <mat-icon>attach_file</mat-icon>
    </button>
  </div>

  <div class="right-side">
    <button mat-icon-button [disabled]="true" aria-label="Disabled button">
      <mat-icon>lock</mat-icon>
    </button>
    <button mat-icon-button aria-label="Submit message" (click)="handleSubmit()" class="fluent-ui-button">
      <span class="fluent-ui-button-icon">
        <svg fill="currentColor" aria-hidden="true" width="1em" height="1em" viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M2.18 2.11a.5.5 0 0 1 .54-.06l15 7.5a.5.5 0 0 1 0 .9l-15 7.5a.5.5 0 0 1-.7-.58L3.98 10 2.02 2.63a.5.5 0 0 1 .16-.52Zm2.7 8.39-1.61 6.06L16.38 10 3.27 3.44 4.88 9.5h6.62a.5.5 0 1 1 0 1H4.88Z"
            fill="currentColor"></path>
        </svg>
      </span>
    </button>
  </div>
</div>