<div fxFill fxLayout="column">
  <mat-toolbar>
    <span>Notifications ({{ notificationsCount$ | async }})</span>
    <div fxFlex></div>
    <button
      *ngIf="notificationsCount$ | async"
      class="remove-all-button"
      mat-icon-button
      (click)="removeAllNotifications()"
      gdcoTrack="RemoveAllNotifications"
      title="Remove all"
    >
      <mat-icon>clear_all</mat-icon>
    </button>
  </mat-toolbar>

  <mat-nav-list *ngIf="updateAvailable$ | async">
    <mat-list-item (click)="onUpdateNotification()">
      <mat-icon mat-list-icon>present_to_all</mat-icon>
      <h4 matLine>An update to the GDCO App is available.</h4>
      <span matLine>Click to refresh the page.</span>
    </mat-list-item>
    <mat-divider></mat-divider>
  </mat-nav-list>

  <mat-nav-list fxFlex class="notification-list">
    <div *ngFor="let notification of currentNotifications$ | async; let i = index">
      <mat-list-item (click)="onNotificationClick(notification)">
        <mat-icon mat-list-icon [style.color]="getIconColor(notification)">{{ getIcon(notification) }}</mat-icon>
        <h4 matLine>{{ notification.message }}</h4>
        <span *ngIf="notification.date" matLine>{{ notification.date | amDateFormat: 'h mm a' }}</span>
        <button mat-icon-button (click)="removeNotification($event, i)" gdcoTrack="RemoveNotification" title="Remove">
          <mat-icon>clear</mat-icon>
        </button>
      </mat-list-item>
      <mat-divider></mat-divider>
    </div>
  </mat-nav-list>
</div>
