<mat-toolbar *ngIf="(isShellV2Enabled$ | async) === false" color="primary" class="shell-toolbar">
  <button mat-icon-button (click)="onMenuButtonClick()" aria-label="Menu" data-test="main-menu">
    <mat-icon>menu</mat-icon>
  </button>
  <ng-content select="gdco-shell-title"></ng-content>

  <span class="stretch"></span>
  <ng-content select="gdco-shell-actions"></ng-content>
</mat-toolbar>

<mat-sidenav-container (backdropClick)="onSidenavBackdropClick()" class="print gdco-background">
  <mat-sidenav class="app-sidenav" mode="over" [opened]="menuOpen$ | async">
    <gdco-menu></gdco-menu>
  </mat-sidenav>

  <mat-sidenav
    class="right-sidenav"
    position="end"
    mode="over"
    [opened]="sidenavOpen$ | async"
    data-html2canvas-ignore="true"
  >
    <ng-container *ngIf="sidenavOpen$ | async">
      <ng-container *ngTemplateOutlet="sidenavTemplate$ | async"></ng-container>
    </ng-container>
  </mat-sidenav>

  <mat-sidenav-content id="_content" tabindex="-1">
    <ng-content select="gdco-shell-banner"></ng-content>
    <ng-content></ng-content>
  </mat-sidenav-content>
</mat-sidenav-container>
