<h4 mat-dialog-title *ngIf="data?.title" class="dialog-label">{{ data.title }}</h4>
<mat-dialog-content class="dialog-content">
  <mat-form-field class="gdco-compact">
    <input matInput [attr.aria-label]="data.title" [formControl]="inputControl" />
    <mat-hint align="end">{{ inputControl?.value?.length || 0 }} / {{ maxLength }}</mat-hint>
    <mat-error *ngIf="inputControl.hasError('required')">Required</mat-error>
  </mat-form-field>
</mat-dialog-content>
<gdco-action-bar align="end">
  <button mat-stroked-button color="primary" mat-dialog-close>{{ data.cancelButtonText || 'Cancel' }}</button>
  <button mat-flat-button color="primary" (click)="onSave()" [disabled]="!isValid">
    {{ data.saveButtonText || 'Save' }}
  </button>
</gdco-action-bar>
